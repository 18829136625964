






































































































































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CAbpTableForFunctional extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private showData!: boolean;

    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;

    private tableFields = [
        // {
        //     key: 'action',
        //     label: ''
        // },
        {
            key: 'gr',
            label: 'Фг'
        },
        {
            key: 'abp',
            label: 'Абп'
        },
        {
            key: 'prg',
            label: 'Программа'
        },
        {
            key: 'ppr',
            label: 'Подпрограмма'
        },
        {
            key: 'spf',
            label: 'Специфика'
        },
        {
            key: 'bip_code',
            label: 'Проект'
        },
        {
            key: 'deviation',
            label: 'Финансовый ' +
                'план на год'
        },
        {
            key: 'january',
            label: 'Январь'
        },
        {
            key: 'february',
            label: 'Февраль'
        },
        {
            key: 'march',
            label: 'Март'
        },
        {
            key: 'april',
            label: 'Апрель'
        },
        {
            key: 'may',
            label: 'Май'
        },
        {
            key: 'june',
            label: 'Июнь'
        },
        {
            key: 'july',
            label: 'Июль'
        },
        {
            key: 'august',
            label: 'Август'
        },
        {
            key: 'september',
            label: 'Сентябрь'
        },
        {
            key: 'october',
            label: 'Октябрь'
        },
        {
            key: 'november',
            label: 'Ноябрь'
        },
        {
            key: 'december',
            label: 'Декабрь'
        }
    ];
    private months = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ]

    private open = false;

    private filter: any = {
        gr: null,
        abp: null,
        prg: null,
        ppr: null,
        spf: null,
        bip_code: null,
        name_ru: null
    };

    private filtered = false;

    private openAll(forceOpen: string) {
        if (forceOpen) {
            if (forceOpen === 'open') {
                this.open = true;
            } else {
                this.open = false;
            }
        } else {
            this.open = !this.open;
        }
        for (const row of this.tableData) {
            row.visible = this.open;
            if ([1, 2, 3, 4, 5].includes(row.type)) {
                row.open = this.open;
            }
            if ([0, 1].includes(row.type)) {
                row.visible = true;
            }
        }
    } // открывает.закрывает все ветки

    private openChilds(parent: any) {
        parent.open = !parent.open;

        for (const row of this.tableData) {
            if (parent.id === row.parent_id) {
                row.visible = parent.open;
                if (!parent.open) {
                    if (row.hasChild && row.open !== parent.open) {
                        this.openChilds(row);
                    }
                    if (row.hasFormChild && row.open !== parent.open) {
                        this.openChilds(row);
                    }
                }
            }
        }
    } // открывает/закрывает ветку до конечного элемента

    private rowClass(item: any, type: any) {
        if (!item || type !== 'row') {
            return;
        }
        if (!item.visible) {
            return 'is-hidden';
        }
    }

    private total(field: string) {
        let sum = 0;
        for (const row of this.tableData) {
            if (row.type === 1) {
                sum += parseFloat(row[field]);
            }

        }
        return Math.round(sum);
    } // итого по заданному полю

    //= ========== фильтрация данных в столбцах ===============

    private filterFunction(row: any, val: any) {
        const {gr, abp, prg, ppr, spf, name_ru, bip_code} = val;
        const isOpen = [
            !gr || Number(gr) === row.gr,
            !abp || Number(abp) === row.abp,
            !prg || Number(prg) === row.prg,
            !ppr || Number(ppr) === row.ppr,
            !spf || Number(spf) === row.spf,
            !bip_code || (row.bip_code ? row.bip_code.toLowerCase().indexOf(bip_code.toLowerCase()) !== -1 : null),
            !name_ru || row.name_ru.toLowerCase().indexOf(name_ru.toLowerCase()) !== -1
        ].every(Boolean);
        if ((gr || abp || prg || ppr || spf || name_ru || bip_code) && !this.filtered) {
            this.filtered = true;
            this.openAll('open');
        }
        if (!(gr || abp || prg || ppr || spf || name_ru || bip_code) && this.filtered) {
            this.filtered = false;
            this.openAll('close');
        }
        return isOpen;
    }
}
