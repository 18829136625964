











































































































































































































import {Component, Vue} from 'vue-property-decorator';
import store from '@/services/store';
import CGuWindow from '@/modules/budget/execution/form/tabs/ipf/GuWindow.vue';
import CInsideWindow from '@/modules/budget/execution/form/tabs/ipf/InsideWindow.vue';
import {Ax} from "@/utils";
import axios from 'axios'
interface IFilter {
    curGr: number;
    abp: number;
    gu: string;
    region: string;
    year: number;
    prg?: number;
    ppr?: number;
    spf?: number;
}

@Component({
    components: {
        'c-gu-window': CGuWindow,
        'c-inside-window': CInsideWindow
    }
})
export default class CIpf extends Vue {
    private moduleCode = '005.001.001'
    private regionBase: any[] = [];
    private region : any | null = null;
    private obl = null;
    private filter: null | IFilter = null;
    private openSecondWindow = false;
    private editAccess: object | any = null;
    private isLoadInside = false;

    private isOnlyGu: boolean = true;

    private isOnlyRegion: boolean = true;

    private getEditAccess() {
        const avModules = store.state.user.userModules
        for (const avModule of avModules) {
            if (avModule.modules === this.moduleCode) {
                this.editAccess = Object.assign({}, avModule)
                break
            }
        }
    }

    private async created() {
        this.year = (new Date()).getFullYear();
        this.getEditAccess()
        await this.getObl();
        await this.loadAbp()
        await this.loadGr();
    }


    private chgLoad(isLoad: boolean) {
        this.isLoadInside = isLoad;
    }

    private get usrId() {
        if (store.state.user.sub === undefined) {
            return null;
        }
        return store.state.user.sub;
    }

    // --------- ГУ / КГУ ------------
    private year: number | null = null;

    // --------- ГУ / КГУ ------------
    private companyBase: any[] = [];
    private curComp: any | null = null;

    private get company(): any[] {
        const result: any[] = [];
        for (const el of this.companyBase) {
            result.push(this.setNameLang(el, 'code'));
        }
        result.sort((a, b) => (a.code > b.code) ? 1 : -1);
        return result;
    }

    // --------- Функ гр. ------------
    private curGr: any | null = null;


    // ------------------ администратор программ abp------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) { this.curAbp = this.setNameLang(this.curAbp, 'abp'); }
        return res;
    }

    // ------------------------ программа --------------------------
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) {
            this.curProg = this.setNameLang(this.curProg, 'prg');
        }
        return res;
    }

    private async chgProg() {
        this.subProgBase = [];
        this.curSubProg = null;
        this.spfBase = [];
        this.curSpf = null;
        if (this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg) {
            await this.loadSubProg();
        }
    }

    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) {
            this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
        }
        return res;
    }

    // ----------------------------специфика------------------------
    private spfBase: any[] = [];
    private curSpf: any | null = null;


    private get spf(): any[] {
        const res: any[] = [];
        for (const el of this.spfBase) {
            res.push(this.setNameLang(el, 'spf'));
        }

        if (this.curSpf !== null) {
            this.curSpf = this.setNameLang(this.curSpf, 'spf');
        }
        return res;
    }

    private async loadAbp() {
        let response: any = [];
        this.abpBase = [];
        if (this.usrId === null) { return; }
        if (response.length === 0) {
            await axios.get(`/api-py/get-abp-by-user-id-region/${this.usrId}/${this.region.code}`)
                .then(res => {
                    response = res.data
                    if (res.status === 500) {
                        throw new Error('empty_list')
                    }
                    if (response.length < 1) {
                        throw new Error('empty_list')
                    }
                })
                .catch(error => {
                    response = []
                    if (error.message === 'empty_list') {
                        this.makeToast('danger', 'Ошибка загрузки адм. программ', 'У вас нет доступных адм. программ');
                    } else if (error.response.status === 500) {
                        this.makeToast('danger', 'Ошибка загрузки адм. программ', 'у вас нет доступных адм. программ');
                    } else {
                        this.makeToast('danger', 'Ошибка загрузки адм. программ', error.toString());
                    }
                });
        }
        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        if (response && response.length > 0) {
            this.abpBase = this.setIdArr(response, 'abp');
            if (this.abpBase && this.abpBase.length > 1) {
                this.isOnlyGu = false;
            }
        }

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
        await this.chgAbp();
    }

    private async chgAbp() {
        if (this.curAbp && this.curAbp.hasOwnProperty('abp')) {
            await this.loadCompany();
        }
    }

    private async getObl() {
        this.regionBase = []
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);
            if (result.status === 200) {
                const json = await result.json();
                this.region = json.region;
                this.obl = json.obl;
            } else {
                this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-obl', error.toString());
        }


        await axios.get('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
            .then(res => {
                const json = res.data
                if (res.status === 500) {
                    this.region = null
                    throw new Error('empty_list')
                }
                if (json.length < 1) {
                    this.region = null
                    throw new Error('empty_list')
                }
                if (json.length > 0) {
                    for (const el of json) {
                        this.regionBase.push(this.setNameLang(el, 'code'))
                    }
                    this.region = this.regionBase[0]
                    this.isOnlyRegion = !(this.regionBase.length > 1);
                }
            })
            .catch(error => {
                if (error.message === 'empty_list') {
                    this.makeToast('danger', 'Ошибка запроса get-user-budget-region', 'У вас нет доступных регионов');
                } else if (error.response.status === 500) {
                    this.makeToast('danger', 'Ошибка запроса get-user-budget-region', 'У вас нет доступных регионов');
                } else {
                    this.makeToast('danger', 'Ошибка запроса get-user-budget-region', error.toString());
                }
            });
    }

    private async loadCompany() {
        // await this.getObl();
        this.loadGr()
        let result: any[] = [];
        this.curComp = null;
        await axios.get(`/api-py/get-gu-by-abp-user-id-region/${this.curAbp.abp}/${this.usrId}/${this.region.code}`)
            .then(res => {
                result = res.data
                if (res.status === 500) {
                    throw new Error('empty_list')
                }
                if (result.length < 1) {
                    throw new Error('empty_list')
                }

            })
            .catch(error => {
                result = [];
                if (error.message === 'empty_list') {
                    this.makeToast('danger', 'Ошибка загрузки ГУ / КГУ', 'У вас нет доступных ГУ / КГУ');
                } else if (error.response.status === 500) {
                    this.makeToast('danger', 'Ошибка загрузки ГУ / КГУ', 'У вас нет доступных ГУ / КГУ');
                } else {
                    this.makeToast('danger', 'Ошибка загрузки ГУ / КГУ', error.toString());
                }
            });

        if (result && result.length > 0) {
            if (result && result.length > 1) {``
                this.isOnlyGu = false;
            }
            this.curComp = this.setNameLang(result[0], 'code');
            // await this.getRegionByCodeGu();
        }
        this.companyBase = result;
        if (this.curComp && this.curComp.hasOwnProperty('code')) {
            await this.chgCurComp();
            await this.chgData();
        }
    }

    private async  chgCurComp() {
        if (this.curComp && this.curAbp.hasOwnProperty('code')) {
            // await this.getRegionByCodeGu();
        }
    }

    private async getRegionByCodeGu() {
        return
        if (this.curComp && this.curComp.id_region) {
            let result: any[] = [];
            try {
                result = await fetch('/api-py/get-region-by-code-gu',
                    {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(this.curComp)
                    })
                    .then(response => response.json());
                if (result && result.length > 0 && result[0].code) {
                    this.region = result[0];
                }
            } catch (error) {
                result = [];
                this.makeToast('danger', 'Ошибка загрузки региона', (error as Error).toString());
            }
        }
    }

    private async loadGr() {
        let result: any[];
        this.curGr = null;
        try {
            result = await fetch(`/api-py/get-func-gr-by-user-id/${this.usrId}`)
                .then(response => response.json());
        } catch (error) {
            result = [];
            this.makeToast('danger', 'Ошибка загрузки Функ. Гр.', error.toString());
        }
        if (result && result.length > 0) {
            this.curGr = result[0];
            // await this.loadAbp();
        }
    }

    private async loadProg() {
        let response: any = [];
        this.progBase = [];

        try {
            response = await fetch(`/api/budget-execution-pf-ipf/prg?year=${this.year}&gu=${this.curComp.code}`);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки программ', error.toString());
        }
        if (response && response.length > 0) {
            response.sort((a: any, b: any) => { return (a.prg - b.prg); });
            this.progBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'prg');
        }

        if (this.progBase.length > 0) {
            this.curProg = this.progBase[0];
        } else {
            this.curProg = null;
        }
        await this.chgProg();
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];

        if (this.curProg !== null) {
            try {
                // response = await fetch(`/api-py/ppr-by-abp-and-prg/${this.curAbp.abp}/${this.curProg.prg}`);
                response = await fetch(`/api/budget-execution-pf-ipf/ppr?year=${this.year}&gu=${this.curComp.code}&prg=${this.curProg.prg}`);                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки подпрограмм', error.toString());
                response = [];
            }
        }
        if (response && response.length > 0) {
            response.sort((a: any, b: any) => { return (a.ppr - b.ppr); });
            this.subProgBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'ppr');
        }

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
        } else {
            this.curSubProg = null;
        }
        await this.chgSubProg();
    }

    private async chgSubProg() {
        this.spfBase = [];
        this.curSpf = null;
        if (this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr) {
            await this.loadCurSpf();
        }
    }

    private async loadCurSpf() {
        this.spfBase = [];
        let response: any = [];

        if (this.curSubProg !== null) {
            try {
                response = await fetch(`/api/budget-execution-pf-ipf?region=${this.region.code}&abp=${this.curAbp.abp}&gu=${this.curComp.code}&year=${this.year}&prg=${this.curProg.prg}&ppr=${this.curSubProg.ppr}&type=obligation`);
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCurSpf()', error.toString());
            }
        }

        response.sort((a: any, b: any) => { return (a.spf - b.spf); });

        response = response.map((el: any) => {
            return {
                name_ru: el.name_ru,
                name_kk: el.name_kk,
                spf: el.spf
            }
        });

        if (response && response.length > 0) {
            this.spfBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', spf: null}, ...response], 'spf');
        }

        if (this.spfBase.length > 0) {
            this.curSpf = this.subProgBase[0];
        } else {
            this.curSpf = null;
        }
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            if (obj[codeName]) {
                txt = obj[codeName] + ' - ' + txt;
            }
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private  setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        if (arr && arr.length > 0) {
            for (const el of arr) {
                if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({idArr: [el.id]}, el));
                }
            }
        }
        return result;
    }

    private chgData() {
        if (typeof this.year === 'number') {
            this.filter = {
                year: this.year,
                curGr: this.curGr? this.curGr.gr: 1,
                abp: this.curAbp.abp,
                gu: this.curComp.code,
                region: this.region.code,
            };
            // this.nameGu = this.curComp.name_ru;
            if (this.openSecondWindow) {
                if (this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg) {
                    this.$set(this.filter, 'prg', this.curProg.prg);
                }
                if (this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr) {
                    this.$set(this.filter, 'ppr', this.curSubProg.ppr);
                }
                if (this.curSpf && this.curSpf.hasOwnProperty('spf') && this.curSpf.spf) {
                    this.$set(this.filter, 'spf', this.curSpf.spf);
                }
                // if (this.curSpf && this.curSpf.hasOwnProperty('spf') && this.curSpf.spf) {
                //     this.$set(this.filter, 'spf', this.curSpf.spf);
                // }
                // this.$set(this.filter, 'prg', this.curProg.prg);
                // this.$set(this.filter, 'ppr', this.curSubProg?.ppr);
            }
        }
        const drop: any = this.$refs.drop;
        drop.hide(true);
    }

    private async toggleOpenWindowBool() {
        if (this.editAccess.access_level === 2 || this.editAccess.access_level === 3) {
            if (this.openSecondWindow) {
                // eslint-disable-next-line
                // @ts-ignore
                const hasChanges = this.$refs.ipfInsideWindow.beforeExit()
                if (hasChanges) {
                    this.$bvModal.msgBoxConfirm(
                        'Сохранить изменения?',
                        {
                            title: 'Подтверждение',
                            size: 'lg',
                            buttonSize: 'sm',
                            okVariant: 'success',
                            okTitle: 'ДА',
                            cancelTitle: 'Отмена',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true
                        })
                        .then(async value => {
                            if (value) {
                                await this.save();
                            }
                            // this.openSecondWindow = false;
                            // this.isLoadInside = false;
                            // setTimeout(() => this.chgData(), 100);
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
                else {
                    this.openSecondWindow = false;
                    this.isLoadInside = false;
                }
            } else {
                this.openSecondWindow = true;
                if (this.openSecondWindow) {
                    await this.loadProg();
                }
                setTimeout(() => this.chgData(), 100);
            }
        } else {
            // для просмотра
            if (this.openSecondWindow) {
                this.openSecondWindow = false;
                this.isLoadInside = false;
                setTimeout(() => this.chgData(), 100);
            } else {
                this.openSecondWindow = true;
                if (this.openSecondWindow) {
                    await this.loadProg();
                }
                setTimeout(() => this.chgData(), 100);
            }
        }
        // this.chgData();
    }

    private showSaveBtn = false;
    private clickSave() {
        this.$bvModal.msgBoxConfirm(
            'Сохранить изменения?',
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async value => {
                if (value) {
                    await this.save();
                }
            })
            .catch(error => {
                console.log(error);
            });
    }
    private async save() {
        this.showSaveBtn = true;
        // eslint-disable-next-line
        // @ts-ignore
        const isSuccess = await this.$refs.ipfInsideWindow.prepareForSave();
        if (isSuccess) {
            await this.prepareIpfForSave();
        }
        this.showSaveBtn = false;
    }

    private async prepareIpfForSave() {
        const item = Object.assign({}, this.filter);
        this.$set(item, 'defective', 'Проверено');
        this.$set(item, 'user_id', this.$store.state.user.sub);
        this.$set(item, 'gr', this.curGr.gr);

        await this.saveIpf(item);
    }

    private async saveIpf(value: any, error = false) {
        try {
            const response = await fetch('/api-py/save-budget-execution-ipf-gu/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(value)
            });
            const result = await response.json();
            if ((response.status === 200) && (result.result === 'success')) {
                // this.makeToast('success', 'Сообщение', 'Данные сохранены (ИПФ)');
            } else {
                throw 'Ошибка сохранения данных. (ИПФ)';
            }
        } catch (e) {
            this.makeToast('danger', 'Предупреждение', e.toString());
        }
    } // сохранение данных

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }


    private downloadRepPayments() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/1/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Проект индивидуального плана финансирования государственного учреждения по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepPayments()', error.toString());
            }
        );
    }


    private downloadRepIndPayments() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/2/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования государственного учреждения по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndPayments()', error.toString());
            }
        );
    }


    private downloadRepIndPayments3() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/3/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndPayments3()', error.toString());
            }
        );
    }

    private downloadRepObligations() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/4/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Проект индивидуального плана финансирования государственного учреждения по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepObligations()', error.toString());
            }
        );
    }

    private downloadRepIndObligations() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/5/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования государственного учреждения по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndObligations()', error.toString());
            }
        );
    }

    private downloadRepIndObligations6() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/6/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndObligations6()', error.toString());
            }
        );
    }

    private downloadRepIndPayments11() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/11/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования по платежам с проектами.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndPayments11()', error.toString());
            }
        );
    }

    private downloadRepIndObligations12() {
        Ax(
            {
                url: '/api-py/get-budget-execution-ipf-report/' + JSON.stringify(this.filter) + '/12/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Индивидуальный план финансирования по обязательствам с проектами.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndObligations12()', error.toString());
            }
        );
    }
}
