





























































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CGuWindow extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private filter!: any;

    @Prop({
        required: true,
        default: null
    })
    private nameGu!: string;

    @Prop({
        required: true,
        default: null
    })
    private edit_access!: boolean;

    private progress: number | null = null;

    private tableFields = [
        {
            key: 'action',
            label: ' '
        },
        {
            key: 'name_ru',
            label: 'Наименование'
        },
        {
            key: 'defective',
            label: 'Дефектная ведомость'
        },
        {
            key: 'status',
            label: 'Статус'
        },
        {
            key: 'comment',
            label: 'комментарий'
        },
        {
            key: 'executor',
            label: 'Исполнитель'
        },
        {
            key: 'date_creation',
            label: 'Дата создания'
        },
        {
            key: 'more',
            label: ''
        }
    ];

    private ipfData: any = [];

    private async created() {
        this.progress = 10;
        this.$watch('filter', await this.chgFilter);
        await this.chgFilter();
    }

    private async chgFilter() {
        await this.loadData();
    }

    private async loadData() {
        this.ipfData.splice(0);
        const dataItem: any = {
            id: 0,
            name_ru: `Индивидуальный план финансирования на ${this.filter.year} год по ${this.nameGu}`,
            gu: this.filter.gu,
            year: this.filter.year,
            defective: 'Отсутствует',
            status: 'Создан',
            comment: 'Комментарий',
            executor: '',
            user_id: this.$store.state.user.sub,
            date_creation: ''
        };
        this.ipfData.push(dataItem);

        let values = [];
        try {
            this.progress = 50;
            const response = await fetch('/api-py/budget-execution-ipf/' + JSON.stringify(this.filter) + '/ipf');
            values = await response.json();
            this.progress = 75;

            if (values.length === 0) {
                this.progress = 100;
                return;
            }
            // eslint-disable-next-line consistent-return
        } catch (error) {
            this.progress = 0;
            this.makeToast('danger', 'Ошибка запроса loadData()', error.toString());
        }
        if (values && values.length > 0) {
            let fullNameExecutor = '';
            try {
                const res = await fetch('/api-py/get-realm-user/' + values[0].user_id);
                const infoAboutUser = await res.json();
                if (!infoAboutUser) {
                    this.progress = 100;
                    return;
                }
                fullNameExecutor = infoAboutUser['lastName'] + ' ' + infoAboutUser['firstName'];
                // eslint-disable-next-line consistent-return
            } catch (error) {
                this.progress = 0;
                this.makeToast('danger', 'Ошибка запроса loadExecutor()', error.toString());
            }

            values.forEach((val: any) => {
                let itemExist = [];
                itemExist = this.ipfData.filter(function (item: any) {
                    if (item.gu === val.gu) {
                        return item;
                    }
                });
                if (itemExist.length > 0) {
                    itemExist[0].id = val.id;
                    // itemExist[0].gu = val.gu;
                    // itemExist[0].year = val.year;
                    itemExist[0].defective = val.defective;
                    // status: 'Создан',
                    // comment: 'Комментарий'
                    itemExist[0].user_id = val.user_id;
                    itemExist[0].date_creation = val.date_creation;
                    itemExist[0].executor = fullNameExecutor;
                }
            });
        }
        this.progress = 100;
    }

    private toggleOpenWindowBool() {
        this.$emit('toggleOpenWindowBool', this.ipfData);
    }

    private openModal() {
        console.log('openModal');
    }

    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
