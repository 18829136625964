













































































































































































































import {Component, Vue} from 'vue-property-decorator';
import store from '@/services/store';
import CAbpWindow from '@/modules/budget/execution/form/tabs/pf-abp/AbpWindow.vue';
import CAbpInsideWindow from '@/modules/budget/execution/form/tabs/pf-abp/AbpInsideWindow.vue';
import {Ax} from '@/utils';

interface IFilter {
    abp: number;
    year: number;
    region?: string;
    prg?: number;
    ppr?: number;
}

@Component({
    components: {
        'c-abp-window': CAbpWindow,
        'c-abp-inside-window': CAbpInsideWindow
    }
})
export default class CPfAbp extends Vue {
    private editAccess = false; // для редактирование
    private access_level: any | null = null; // уровень доступа (1 просмотр, 2 редактирование)

    private budgetLevel: number[] = [];
    private curYear: number = (new Date()).getFullYear();
    private region = '';
    private reg: any | null = null;
    private obl = null;
    private listReg: any | null = null;
    private filter: null | IFilter = null;
    private nameAbp = '';
    private openSecondWindow = false;
    private isLoadInside = false;

    private async created() {
        this.curYear = (new Date()).getFullYear();
        await this.getAccess();
        await this.loadRegions();
        // await this.getObl();
        // eslint-disable-next-line
        const that = this;
        this.$watch('usrId', that.loadBudgetLevel);

        await this.loadBudgetLevel();
        // this.reg = this.region;
    }

    private async getAccess() {
        try {
            const result = await fetch(`/api-py/budget-execution-get-access-info/005.001.002/${this.usrId}`);
            if (result.status === 200) {
                const res = await result.json();
                if (res) {
                    this.access_level = res['access_level']
                    if (this.access_level === 2) {
                        this.editAccess = true;
                    }
                }
            } else {
                this.makeToast('danger', 'get-access', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-access', error.toString());
        }
    }

    private get usrId() {
        if (store.state.user.sub === undefined) {
            return null;
        }
        return store.state.user.sub;
    }

    // ------------------ администратор программ abp------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) {
            this.curAbp = this.setNameLang(this.curAbp, 'abp');
        }
        return res;
    }

    private async chgAbp() {
        this.progBase = [];
        this.curProg = null;
        this.subProgBase = [];
        this.curSubProg = null;
        this.spfBase = [];
        this.curSpf = null;
        if (this.curAbp && this.curAbp.hasOwnProperty('abp') && this.curAbp.abp) {
            await this.loadProg();
        }
    }

    // ------------------------ regions --------------------------
    private regBase: any | null = [];
    private curReg: any | null = null;

    private get regions(): any[] {
        const res: any[] = [];
        if (this.regBase) {
            for (const el of this.regBase) {
                res.push(this.setNameLang(el, 'code'));
            }
        }
        if (this.curReg !== null) {
            this.curReg = this.setNameLang(this.curReg, 'code');
        }
        return res;
    }


    // ------------------------ программа --------------------------
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) {
            this.curProg = this.setNameLang(this.curProg, 'prg');
        }
        return res;
    }

    private async chgProg() {
        this.subProgBase = [];
        this.curSubProg = null;
        this.spfBase = [];
        this.curSpf = null;
        if (this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg) {
            await this.loadSubProg();
        }
    }

    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) {
            this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
        }
        return res;
    }

    private async chgSubProg() {
        this.spfBase = [];
        this.curSpf = null;
        if (this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr) {
            await this.loadCurSpf();
        }
    }

    // -------------------- load
    private async loadBudgetLevel() {
        this.budgetLevel = [];
        if (this.usrId === null) {
            return;
        }
        let result = [];
        try {
            result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки уровня бюджета', error.toString());
            return;
        }
        for (const el of result) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const indx = el.budget_level.lastIndexOf('_');
            if (indx >= 0) {
                this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
            }
        }
        if (this.budgetLevel.length === 0) {
            this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!');
        }
        await this.loadAbp();
    }

    private async loadRegions() {
        let response: any = [];
        try {
            response = await fetch(`/api-py/user-region-msu-by-uid/${this.usrId}`).then(response => response.json());
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки Регионов', error.toString());
        }
        this.regBase = response;

        if (this.regBase.length > 0) {
            this.curReg = this.regBase[0];
        } else {
            this.curReg = null;
        }
    }

    private async loadAbp() {
        if (!this.budgetLevel || this.budgetLevel.length < 1) {
            return;
        }
        const url = `/api-py/get-abp-by-user-id-region/${this.usrId}/${this.curReg.code}`
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
            } else {
                this.makeToast('danger', 'Ошибка загрузки АБП', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
        }

        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
        } else {
            this.curAbp = null;
        }
        if (!this.openSecondWindow) {
            await this.chgData();
        }
    }

    // private async getObl() {
    //     let region = this.region;
    //     try {
    //         const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);
    //         if (result.status === 200) {
    //             const json = await result.json();
    //             region = json.region;
    //             this.obl = json.obl;
    //         } else {
    //             this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
    //         }
    //     } catch (error) {
    //         this.makeToast('danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
    //     }
    //
    //     try {
    //         await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.usrId)
    //             .then(response => response.json())
    //             .then(json => {
    //                 if (json.length && json[0].code) { region = json[0].code; }
    //             });
    //     } catch (error) {
    //         this.makeToast('danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
    //     }
    //     this.region = region;
    // }

    // private async loadRegions() {
    //     try {
    //         const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + store.state.user.sub);
    //         this.listReg = await response.json();
    //         this.listReg.forEach((item: any) => {
    //             if (item.code === this.region) {
    //                 this.reg = item;
    //             }
    //         })
    //     } catch (error) {
    //         this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
    //     }
    // } // справочник регионов

    private async loadProg() {
        let response: any = [];
        this.progBase = [];

        try {
            response = await fetch(`/api-py/have-prg-in-execution-ipf-by-abp/${this.curAbp.abp}`);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки программ', error.toString());
        }
        if (response && response.length > 0) {
            this.progBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'prg');
        }

        if (this.progBase.length > 0) {
            this.curProg = this.progBase[0];
            await this.chgProg();
        } else {
            this.curProg = null;
        }
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];

        if (this.curProg !== null) {
            try {
                response = await fetch(`/api-py/have-ppr-in-execution-ipf-by-abp-and-prg/${this.curAbp.abp}/${this.curProg.prg}`);
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки подпрограмм', error.toString());
                response = [];
            }
        }
        if (response && response.length > 0) {
            this.subProgBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'ppr');
        }

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
            await this.chgSubProg();
        } else {
            this.curSubProg = null;
        }
    }

    private spfBase: any[] = [];
    private curSpf: any | null = null;


    private get spf(): any[] {
        const res: any[] = [];
        for (const el of this.spfBase) {
            res.push(this.setNameLang(el, 'spf'));
        }

        if (this.curSpf !== null) {
            this.curSpf = this.setNameLang(this.curSpf, 'spf');
        }
        return res;
    }

    private async loadCurSpf() {
        this.spfBase = [];
        let response: any = [];

        if (this.curSubProg !== null) {
            try {
                response = await fetch(`/api-py/have-spf-in-execution-ipf-by-abp-and-prg-ppr/${this.curAbp.abp}/${this.curProg.prg}/${this.curSubProg.ppr}`);
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCurSpf()', error.toString());
            }
        }

        if (response && response.length > 0) {
            this.spfBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', spf: null}, ...response], 'spf');
        }

        if (this.spfBase.length > 0) {
            this.curSpf = this.subProgBase[0];
        } else {
            this.curSpf = null;
        }
    }

    private chgLoad(isLoad: boolean) {
        this.isLoadInside = isLoad;
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            if (obj[codeName]) {
                txt = obj[codeName] + ' - ' + txt;
            }
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        if (arr && arr.length > 0) {
            for (const el of arr) {
                if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({idArr: [el.id]}, el));
                }
            }
        }
        return result;
    }

    private async chgData() {
        this.filter = {
            year: this.curYear,
            abp: this.curAbp.abp,
        };
        if (this.curReg && this.curReg.hasOwnProperty('code') && this.curReg.code) {
            this.$set(this.filter, 'region', this.curReg.code);
        }
        this.nameAbp = this.curAbp.name_ru;
        if (this.openSecondWindow) {
            if (this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg) {
                this.$set(this.filter, 'prg', this.curProg.prg);
            }
            if (this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr) {
                this.$set(this.filter, 'ppr', this.curSubProg.ppr);
            }
            if (this.curSpf && this.curSpf.hasOwnProperty('spf') && this.curSpf.spf) {
                this.$set(this.filter, 'spf', this.curSpf.spf);
            }
        }
    }

    private async toggleOpenWindowBool() {
        this.openSecondWindow = !this.openSecondWindow;
        if (this.openSecondWindow) {
            await this.loadProg();
        }
        setTimeout(async () => {
            await this.chgData();
        }, 100);
    }

    private showSaveBtn = false;

    private async save() {
        this.showSaveBtn = true;
        // eslint-disable-next-line
        // @ts-ignore
        await this.$refs.ipfInsideWindow.prepareForSave();
        await this.prepareIpfForSave();
        this.showSaveBtn = false;
    }

    private async prepareIpfForSave() {
        const ipfValues = [];
        // let error = false;
        const item = Object.assign({}, this.filter);
        this.$set(item, 'defective', 'Проверено');
        ipfValues.push(item);

        if (ipfValues.length > 0) {
            await this.saveIpf(ipfValues);
        } else {
            this.makeToast('warning', 'Сообщение', 'Данные не сохранены (ИПФ)');
        }
    }

    private async saveIpf(values: any, error = false) {
        try {
            const response = await fetch('/api-py/save-budget-execution-ipf-gu/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            });
            const result = await response.json();
            if ((response.status === 200) && (result.result === 'success')) {
                // this.makeToast('success', 'Сообщение', 'Данные сохранены (ИПФ)');
            } else {
                throw 'Ошибка сохранения данных. (ИПФ)';
            }
        } catch (e) {
            this.makeToast('danger', 'Предупреждение', e.toString());
        }
    } // сохранение данных

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }


    private downloadRepObligations() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/9/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Проект плана финансирования администратора бюджетных программ по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepObligations()', error.toString());
            }
        );
    }

    private downloadRepIndObligations() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/10/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'План финансирования администратора бюджетных программ по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndObligations()', error.toString());
            }
        );
    }

    private downloadRepIndAbpPayments20() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/20/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'План финансирования администратора бюджетных программ по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndAbpPayments20()', error.toString());
            }
        );
    }

    private downloadRepIndAbpObligations21() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/21/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'План финансирования администратора бюджетных программ по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndAbpObligations21()', error.toString());
            }
        );
    }

    private downloadRepPayments() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/7/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Проект плана финансирования администратора бюджетных программ по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepPayments()', error.toString());
            }
        );
    }

    private downloadRepIndPayments() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/8/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'План финансирования администратора бюджетных программ по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndPayments()', error.toString());
            }
        );
    }
}
