


















































































































































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CSpfTable extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private showData!: boolean;

    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;
    @Prop({
        required: true,
        default: null
    })
    private tableDataParents!: any;
    private tableFields = [
        {
            key: 'action',
            label: ''
        },
        {
            key: 'gr',
            label: 'Гр'
        },
        {
            key: 'pgr',
            label: 'Фпг'
        },
        {
            key: 'abp',
            label: 'Абп'
        },
        {
            key: 'prg',
            label: 'Программа'
        },
        {
            key: 'ppr',
            label: 'Подпрограмма'
        },
        {
            key: 'spf',
            label: 'Специфика'
        },
        {
            key: 'bip_code',
            label: 'Проект'
        },
        // {
        //     key: 'name_ru',
        //     label: 'Наименование'
        // },
        {
            key: 'deviation',
            label: 'Финансовый ' +
                'план на год'
        },
        {
            key: 'january',
            label: 'Январь'
        },
        {
            key: 'february',
            label: 'Февраль'
        },
        {
            key: 'march',
            label: 'Март'
        },
        {
            key: 'april',
            label: 'Апрель'
        },
        {
            key: 'may',
            label: 'Май'
        },
        {
            key: 'june',
            label: 'Июнь'
        },
        {
            key: 'july',
            label: 'Июль'
        },
        {
            key: 'august',
            label: 'Август'
        },
        {
            key: 'september',
            label: 'Сентябрь'
        },
        {
            key: 'october',
            label: 'Октябрь'
        },
        {
            key: 'november',
            label: 'Ноябрь'
        },
        {
            key: 'december',
            label: 'Декабрь'
        }
    ];

    private open = false;

    private filter: any = {
        gr: null,
        pgr: null,
        abp: null,
        prg: null,
        ppr: null,
        spf: null,
        bip_code: null,
        name_ru: null
    };
    private calcFlds = [
        'plan',
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ];

    private filtered = false;

    private openAll(forceOpen: string) {
        if (forceOpen) {
            if (forceOpen === 'open') {
                this.open = true;
            } else {
                this.open = false;
            }
        } else {
            this.open = !this.open;
        }
        for (const row of this.tableData) {
            if (row.type === 1) {
                if (!row.is_children_loaded) {
                    continue
                }
            }
            row.visible = this.open;
            if ([1, 2, 3, 4, 5, 6].includes(row.type)) {
                row.open = this.open;
            }
            if ([0, 1].includes(row.type)) {
                row.visible = true;
            }
        }
    } // открывает.закрывает все ветки

    private openChilds(parent: any, idx: any) {
        parent.open = !parent.open;
        if (parent.type === 1) {
            if (!parent.is_children_loaded) {
                this.loadCildren(parent, idx)
                parent.is_children_loaded = true
            }
        }
        if (!parent.open) {
            for (let i = idx + 1; i < this.tableData.length; i++) {
                if (this.tableData[i].type <= parent.type) {
                    break
                } else {
                    this.tableData[i].visible = false
                }
            }
        } else {
            for (let i = idx + 1; i < this.tableData.length; i++) {
                if (this.tableData[i].type === parent.type) {
                    break
                } else {
                    if (this.tableData[i].type - parent.type === 1) {
                        this.tableData[i].visible = true
                        this.tableData[i].open = false
                    }
                }
            }
        }

    } // открывает/закрывает ветку до конечного элемента

    private rowClass(item: any, type: any) {
        if (!item || type !== 'row') {
            return;
        }
        if (!item.visible) {
            return 'is-hidden';
        }
    }

    private total(field: string) {
        let sum = 0;
        for (const row of this.tableData) {
            if (row.type === 1) {
                sum += parseFloat(row[field]);
            }

        }
        return Math.round(sum);
    } // итого по заданному полю

    //= ========== фильтрация данных в столбцах ===============

    private filterFunction(row: any, val: any) {
        const {gr, pgr, abp, prg, ppr, spf, name_ru, bip_code} = val;
        const isOpen = [
            !gr || Number(gr) === row.gr,
            !pgr || Number(pgr) === row.pgr,
            !abp || Number(abp) === row.abp,
            !prg || Number(prg) === row.prg,
            !ppr || Number(ppr) === row.ppr,
            !spf || Number(spf) === row.spf,
            !bip_code || (row.bip_code ? row.bip_code.toLowerCase().indexOf(bip_code.toLowerCase()) !== -1 : null),
            !name_ru || row.name_ru.toLowerCase().indexOf(name_ru.toLowerCase()) !== -1
        ].every(Boolean);
        if ((gr || pgr || abp || prg || ppr || spf || name_ru || bip_code) && !this.filtered) {
            this.filtered = true;
            this.openAll('open');
        }
        if (!(gr || pgr || abp || prg || ppr || spf || name_ru || bip_code) && this.filtered) {
            this.filtered = false;
            this.openAll('close');
        }
        return isOpen;
    }
    private getTableParent(talbe:any, field:any, value:any) {
        for (let i = 0; i < talbe.length; i++) {
            if (talbe[i][field] === value) return talbe[i]
        }
    }
    private loadCildren(parent_id:any, idx:any) {
        let parent: any
        // eslint-disable-next-line prefer-const
        parent = this.getTableParent(this.tableDataParents, 'id', parent_id.id)
        console.log(parent)
        parent_id['child_list'] = parent.child_list
        let itx = idx
        for (const item of parent_id.child_list) {
            const dif = this.tableData.length
            this.createChilds(item, parent_id.id, itx, 2)
            itx += this.tableData.length - dif
        }
        for (let i = idx + 1; i < this.tableData.length; i++) {
            if (this.tableData[i].type === 1) break
            this.templateData(this.tableData[i])
        }
        delete parent_id.child_list
    }
    private templateData(dataItem: any) {
        Object.defineProperty(dataItem, 'deviation', {
            get: function () {
                const sum = (parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june)
                    + parseFloat(dataItem.july) + parseFloat(dataItem.august) + parseFloat(dataItem.september)
                    + parseFloat(dataItem.october) + parseFloat(dataItem.november) + parseFloat(dataItem.december));
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
    }
    private createChilds(elem: any, parent_id: any, idx = 0, row_type: any) {
        const that = this;
        // создание таблицы на основе дерева
        const item = Object.assign({}, elem);
        this.$set(item, 'parent_id', parent_id);
        this.$set(item, 'visible', false);
        Object.defineProperty(item, 'parent', {
            get: function () {
                for (const row of that.tableData) {
                    if (item.parent_id === row.id) {
                        return row;
                    }
                }
                return null;
            }
        });

        if (item.hasOwnProperty('type')) {
            this.$set(item, 'open', false);
            this.$set(item, 'hasChild', true);
            this.calcFlds.forEach(field => {
                Object.defineProperty(item, field, {
                    get: function () {
                        return that.reSum(item, field);
                    }
                });
            });
        } else {
            this.$set(item, 'open', false);
            this.$set(item, 'hasChild', true);
        }
        item.type = row_type
        if (item) {
            this.$set(item, 'index', idx + 1);
            that.tableData.splice(idx + 1, 0, item)
        }

        if (item.hasOwnProperty('hasChild')) {
            if (item.hasOwnProperty('form_child_list') && item.form_child_list && item.form_child_list.length > 0) {
                let itx = idx + 1
                for (const ch of item.form_child_list) {
                    const dif = this.tableData.length
                    this.createChilds(ch, item.id, itx, row_type + 1);
                    itx += this.tableData.length - dif
                }
                item.form_child_list = [];
            } else if (item.hasOwnProperty('child_list') && item.child_list && item.child_list.length > 0) {
                let itx = idx + 1
                for (const ch of item.child_list) {
                    const dif = this.tableData.length
                    this.createChilds(ch, item.id, itx, row_type + 1);
                    itx += this.tableData.length - dif
                }
                delete item.child_list;
            } else if (item.hasOwnProperty('spf_list') && item.spf_list && item.spf_list.length > 0) {
                let itx = idx + 1
                for (const ch of item.spf_list) {
                    const dif = this.tableData.length
                    this.createChilds(ch, item.id, itx, row_type + 1);
                    itx += this.tableData.length - dif
                }
                delete item.spf_list;
            }
        }
    }
    private reSum(parent:any, field:any) {
        let sum = 0;
        for (const row of this.tableData) {
            if ((row.parent_id === parent.id)) {
                if (!row[field]) {
                    sum += 0;
                } else {
                    sum += parseFloat(row[field]);
                }
                // sum += parseFloat(row[field]);
            }
        }

        return Math.round(sum);
    }
}
