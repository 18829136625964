<template>
    <div>
        <div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!---------------------год------------------------->
                            <div class="filter-block">
                                <b-form-group label="Год">
                                    <b-form-input
                                        type="number"
                                        v-model="repYear"
                                        ref="yearRef"
                                    ></b-form-input>
                                </b-form-group>
                            </div>

                            <!---------------------регион------------------------->
                            <div class="filter-block">
                                <b-form-group label="Регион">
                                    <multiselect
                                        v-model="curReg"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выберите регион"
                                        :options="regions"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="loadAbp"
                                        ref="regionsRef"
                                    >
                                        <template
                                            slot="singleLabel"
                                            slot-scope="{ option }"
                                        >
                                            <span>{{ option.name }}</span>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <!----------------Администратор программ abp------------------->
                            <div class="filter-block">
                                <b-form-group label="АБП">
                                    <multiselect
                                        v-model="curAbp"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выберите АБП"
                                        :options="abp"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgAbp"
                                        ref="abpRef"
                                    >
                                        <template
                                            slot="singleLabel"
                                            slot-scope="{ option }"
                                        >
                                            <span>{{ option.name }}</span>
                                        </template>
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <!-------------Программа prog---------------------->
                            <div class="filter-block">
                                <b-form-group label="Программа">
                                    <multiselect
                                        v-model="curProg"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать программу"
                                        :options="prog"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgProg"
                                        ref="curProgRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!------------------------Подпрограмма subProg------------------------------------->
                            <div class="filter-block">
                                <b-form-group label="Подпрограмма">
                                    <multiselect
                                        v-model="curSubProg"
                                        track-by="name"
                                        label="name"
                                        placeholder="Выбрать подпрограмму"
                                        :options="subProg"
                                        :searchable="true"
                                        :allow-empty="true"
                                        :show-labels="false"
                                        @input="chgSubProg"
                                        ref="subProgRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>
                            <!------------------------Подпрограмма spf------------------------------------->
                            <div class="filter-block">
                                <b-form-group label="Специфика">
                                    <multiselect
                                        v-model="curSpf"
                                        track-by="spf"
                                        label="name"
                                        placeholder="Выбрать специфику"
                                        :options="spf"
                                        :searchable="true"
                                        :allow-empty="true"
                                        :show-labels="false"
                                        ref="spfRef"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <div class="filter-block">
                                <b-button variant="primary" @click="chgData">Открыть</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
                <div class="filter-actions">
                    <!--                    <b-button variant="light">&#128424;</b-button>-->
                    <b-button v-if="editAccess.access_level === 2 || editAccess.access_level === 3" variant="primary">Подписать</b-button>
                </div>
            </div>
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" @click="openFilterByRef('yearRef')">
                    {{ repYear }}
                </span>
                <span class="item-breadcrumb" v-if="curReg" @click="openFilterByRef('regionsRef')">
                    {{ curReg.name }}
                </span>
                <span class="item-breadcrumb" v-if="curAbp && curAbp.hasOwnProperty('abp') && curAbp.abp"
                      @click="openFilterByRef('abpRef')">
                    {{ curAbp.abp }}-АБП
                </span>
                <span class="item-breadcrumb" v-if="curProg && curProg.hasOwnProperty('prg') && curProg.prg"
                      @click="openFilterByRef('curProgRef')">
                    {{ curProg.prg }}-БП
                </span>
                <span class="item-breadcrumb" v-if="curSubProg && curSubProg.hasOwnProperty('ppr') && curSubProg.ppr"
                      @click="openFilterByRef('subProgRef')">
                    {{ curSubProg.ppr }}-БПП
                </span>
                <span :title="curSpf.name" class="item-breadcrumb" v-if="curSpf && curSpf.hasOwnProperty('spf') && curSpf.spf"
                      @click="openFilterByRef('spfRef')">
                    {{ curSpf.name.substring(0, 40) }}...
                </span>
            </div>
        </div>

        <div>
            <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
            <div>
                <b-tabs class="tabs-in-tabs">
                    <b-tab>
                        <template #title>
                            <button class="icon-btn" @click="toggleShowObligation">
                                <i class="icon icon-keyboard icon-rotate-270" v-if="showObligation"></i>
                                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
                            </button>
                            <span class="ml-2">
                                Обязательства
                            </span>
                        </template>
                        <div class="top-table-line"></div>
                        <c-spf-table :table-data-parents="budgetFormObligationData" :show-data="showObligation" :table-data="budgetFormObligation"/>
                    </b-tab>
                </b-tabs>
            </div>
            <div>
                <b-tabs class="tabs-in-tabs">
                    <b-tab>
                        <template #title>
                            <button class="icon-btn" @click="toggleShowPayment">
                                <i class="icon icon-keyboard icon-rotate-270" v-if="showPayment"></i>
                                <i class="icon icon-keyboard icon-rotate-90" v-else></i>
                            </button>
                            <span class="ml-2">
                            Платежи
                        </span>
                        </template>
                        <div class="top-table-line"></div>
                        <c-spf-table :table-data-parents="budgetFormPaymentData" :show-data="showPayment" :table-data="budgetFormPayment"/>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/services/store';
import CSpfTable from '../components/SpfTable.vue';
import {Ax} from '@/utils';
import axios from "axios";

export default {
    name: 'BudgetExecutionSpf',
    components: {CSpfTable},
    data() {
        return {
            budgetFormObligation: [],
            budgetFormObligationData: [],
            budgetFormPayment: [],
            budgetFormPaymentData: [],
            calcFlds: [
                'plan',
                'january',
                'february',
                'march',
                'april',
                'may',
                'june',
                'july',
                'august',
                'september',
                'october',
                'november',
                'december'
            ],

            repYear: 0,
            obl: 0,
            listReg: null,
            reg: null,

            months: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
            filter: null,

            bar: 0,
            isLoad: false,
            showObligation: true,
            showPayment: true,

            abpBase: [],
            curAbp: null,

            regBase: [],
            curReg: null,

            progBase: [],
            curProg: null,

            subProgBase: [],
            curSubProg: null,

            spfBase: [],
            curSpf: null,

            editAccess: null,
            moduleCode: '005.001.005'
        };
    },

    async created() {
        this.getEditAccess();
        const date = new Date();
        this.repYear = date.getFullYear();
    },

    async mounted() {
        await this.loadRegions();

        await this.loadAbp();
        this.bar = 100;
    },

    beforeUpdate() {
        this.budgetFormObligation.forEach((row) => {
            row._rowVariant = 'info';
        });
        this.budgetFormPayment.forEach((row) => {
            row._rowVariant = 'info';
        });
    },

    methods: {
        getEditAccess() {
            const avModules = store.state.user.userModules
            for (const avModule of avModules) {
                if (avModule.modules === this.moduleCode) {
                    this.editAccess = Object.assign({}, avModule)
                    break
                }
            }
        },
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.reg = json.region;
                    });
                this.bar = 20;
                await this.loadRegions();
            } catch (error) {
                this.bar = 100;
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        async loadRegions() {
            let response =[];
            try {
                response = await fetch(`/api-py/user-region-msu-by-uid/${this.usrId}`).then(response => response.json());
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки Регионов', error.toString());
            }
            this.regBase = response;

            if (this.regBase.length > 0) {
                this.curReg = this.regBase[0];
            } else {
                this.curReg = null;
            }
        },

        async loadAbp() {
            const url = `/api-py/get-abp-by-user-id-region/${this.usrId}/${this.curReg.code}`
            let response = [];
            try {
                response = await fetch(url);
                if (response.status === 200) {
                    response = await response.json();
                } else {
                    this.makeToast('danger', 'Ошибка загрузки АБП', `${response.status} - ${response.statusText}`);
                }
            } catch (error) {
                response = [];
                this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
            }

            if (response && response.length > 0) {
                response.sort((a, b) => (a.abp - b.abp > 0) ? 1 : -1);
                this.abpBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'abp');
            }

            if (this.abpBase.length > 0) {
                this.curAbp = this.abpBase[0];
            } else {
                this.curAbp = null;
            }
            await this.chgAbp();
            // await this.chgData();

        },
        async chgAbp() {
            this.progBase = [];
            this.curProg = null;
            this.subProgBase = [];
            this.curSubProg = null;
            this.spfBase = [];
            this.curSpf = null;
            if (this.curAbp && this.curAbp.hasOwnProperty('abp') && this.curAbp.abp) {
                await this.loadProg();
            }
        },

        async loadProg() {
            let response = [];
            this.progBase = [];

            try {
                response = await fetch(`/api-py/have-prg-in-execution-ipf-by-abp/${this.curAbp.abp}`);
                response = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки программ', error.toString());
            }
            if (response && response.length > 0) {
                this.progBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'prg');
            }

            if (this.progBase.length > 0) {
                this.curProg = this.progBase[0];
                await this.chgProg();
            } else {
                this.curProg = null;
            }
        },

        async chgProg() {
            this.subProgBase = [];
            this.curSubProg = null;
            this.spfBase = [];
            this.curSpf = null;
            if (this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg) {
                await this.loadSubProg();
            }
        },

        async loadSubProg() {
            let response = [];
            this.subProgBase = [];

            if (this.curProg !== null) {
                try {
                    response = await fetch(`/api-py/have-ppr-in-execution-ipf-by-abp-and-prg/${this.curAbp.abp}/${this.curProg.prg}`);
                    response = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка загрузки подпрограмм', error.toString());
                    response = [];
                }
            }
            if (response && response.length > 0) {
                this.subProgBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'ppr');
            }

            if (this.subProgBase.length > 0) {
                this.curSubProg = this.subProgBase[0];
                await this.chgSubProg();
            } else {
                this.curSubProg = null;
            }
        },

        async chgSubProg() {
            this.spfBase = [];
            this.curSpf = null;
            if (this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr) {
                await this.loadCurSpf();
            }
        },

        async loadCurSpf() {
            this.spfBase = [];
            let response = [];

            if (this.curSubProg !== null) {
                try {
                    response = await fetch(`/api-py/have-spf-in-execution-ipf-by-abp-and-prg-ppr/${this.curAbp.abp}/${this.curProg.prg}/${this.curSubProg.ppr}`);
                    response = await response.json();
                } catch (error) {
                    this.makeToast('danger', 'Ошибка запроса loadCurSpf()', error.toString());
                }
            }

            if (response && response.length > 0) {
                this.spfBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', spf: null}, ...response], 'spf');
            }

            if (this.spfBase.length > 0) {
                this.curSpf = this.subProgBase[0];
            } else {
                this.curSpf = null;
            }
        },

        async chgData() {
            this.$refs.drop.hide(true);

            this.budgetFormObligation.splice(0);
            this.budgetFormObligationData.splice(0);
            this.budgetFormPayment.splice(0);
            this.budgetFormPaymentData.splice(0);
            // await this.loadDataForObligation();
            // await this.loadDataForPayment();
            await this.prepareFormObligation();
            this.bar = 50;
            await this.prepareFormPayment();
            this.bar = 100;
        },


        async prepareFormObligation() {
            this.budgetFormObligation.splice(0);
            this.budgetFormObligationData.splice(0);
            const dataOblig = await this.getData('obligation');
            if (dataOblig && dataOblig.length > 0) {
                for (const item of dataOblig) {
                    this.budgetFormObligationData.push(Object.assign({}, item))
                    this.createTable(item, 0, 'obligation');
                }
            }
            this.budgetFormObligation.forEach(el => {
                this.templateData(el);
            });
        }, // подготовка отображения данных обязательств

        async prepareFormPayment() {
            this.budgetFormPayment.splice(0);
            this.budgetFormPaymentData.splice(0);
            const dataPayment = await this.getData('payment');
            if (dataPayment && dataPayment.length > 0) {
                for (const item of dataPayment) {
                    this.budgetFormPaymentData.push(Object.assign({}, item))
                    this.createTable(item, 0, 'payment');
                }
            }
            this.budgetFormPayment.forEach(el => {
                this.templateData(el);
            });
        }, // подготовка отображения данных платежей

        templateData(dataItem) {
            Object.defineProperty(dataItem, 'deviation', {
                get: function () {
                    const sum = (parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                        + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june)
                        + parseFloat(dataItem.july) + parseFloat(dataItem.august) + parseFloat(dataItem.september)
                        + parseFloat(dataItem.october) + parseFloat(dataItem.november) + parseFloat(dataItem.december));
                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                }
            });
        },

        async getData(type) {
            let values = [];
            try {
                let response = [];
                const queryString = {
                    year: this.repYear,
                    region: this.curReg.code,
                    type: type
                };
                if (this.curAbp && this.curAbp.hasOwnProperty('abp') && this.curAbp.abp) {
                    this.$set(queryString, 'abp', this.curAbp.abp);
                }
                if (this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg) {
                    this.$set(queryString, 'prg', this.curProg.prg);
                }
                if (this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr) {
                    this.$set(queryString, 'ppr', this.curSubProg.ppr);
                }
                if (this.curSpf && this.curSpf.hasOwnProperty('spf') && this.curSpf.spf) {
                    this.$set(queryString, 'spf', this.curSpf.spf);
                }
                response = await axios.get('/api/budget-execution/spf', {params: queryString});
                response = response.data;
                values = await response;

                if (values.length === 0) {
                    return;
                }

                // eslint-disable-next-line consistent-return
                return values;
            } catch (error) {
                this.$emit('chgLoad', false);
                this.makeToast('danger', `Ошибка запроса ${type} in loadData()`, error.toString());
            }
        },

        createTable(elem, parent_id, typeTable) {
            const that = this;
            // создание таблицы на основе дерева
            const item = Object.assign({}, elem);

            this.$set(item, 'parent_id', parent_id);
            this.$set(item, 'visible', true);
            if (item.hasOwnProperty('type') && item.type === 1) item.is_children_loaded = false
            Object.defineProperty(item, 'parent', {
                get: function () {
                    if (typeTable === 'obligation') {
                        for (const row of that.budgetFormObligation) {
                            if (item.parent_id === row.id) {
                                return row;
                            }
                        }
                        return null;
                    } else if (typeTable === 'payment') {
                        for (const row of that.budgetFormPayment) {
                            if (item.parent_id === row.id) {
                                return row;
                            }
                        }
                        return null;
                    }
                }
            });
            // const sums = this.sumOfChildren(item, typeTable)
            if (item.hasOwnProperty('type')) {
                this.$set(item, 'open', false);
                this.$set(item, 'hasChild', true);
                // this.calcFlds.forEach(field => {
                //     Object.defineProperty(item, field, {
                //         get: function () {
                //             return sums[field]
                //         }
                //     });
                // });
            } else {
                this.$set(item, 'open', false);
                this.$set(item, 'hasChild', true);
            }

            if (item) {
                if (typeTable === 'obligation') {
                    this.$set(item, 'index', this.budgetFormObligation.length);
                    this.$set(this.budgetFormObligation, this.budgetFormObligation.length, item);
                } else if (typeTable === 'payment') {
                    this.$set(item, 'index', this.budgetFormPayment.length);
                    this.$set(this.budgetFormPayment, this.budgetFormPayment.length, item);
                }
            }

            if (item.hasOwnProperty('hasChild')) {
                if (item.hasOwnProperty('form_child_list') && item.form_child_list && item.form_child_list.length > 0) {
                    // for (const ch of item.form_child_list) {
                    //     this.createTable(ch, item.id, typeTable);
                    // }
                    item.form_child_list = [];
                } else if (item.hasOwnProperty('child_list') && item.child_list && item.child_list.length > 0) {
                    // for (const ch of item.child_list) {
                    //     this.createTable(ch, item.id, typeTable);
                    // }
                    delete item.child_list;
                } else if (item.hasOwnProperty('spf_list') && item.spf_list && item.spf_list.length > 0) {
                    // for (const ch of item.spf_list) {
                    //     this.createTable(ch, item.id, typeTable);
                    // }
                    delete item.spf_list;
                }
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        reSum(parent, field, typeTable) {
            let sum = 0;
            if (typeTable === 'obligation') {
                this.budgetFormObligation.forEach((row) => {
                    if ((row.parent_id === parent.id)) {
                        if (!row[field]) {
                            sum += 0;
                        } else {
                            sum += parseFloat(row[field]);
                        }
                        // sum += parseFloat(row[field]);
                    }
                });
            } else if (typeTable === 'payment') {
                this.budgetFormPayment.forEach((row) => {
                    if ((row.parent_id === parent.id)) {
                        if (!row[field]) {
                            sum += 0;
                        } else {
                            sum += parseFloat(row[field]);
                        }
                        // sum += parseFloat(row[field]);
                    }
                });
            }

            return Math.round(sum);
        }, // пересчет суммы
        getTableParent(talbe, field, value) {
            for (let i = 0; i < talbe.length; i++) {
                if (talbe[i][field] === value) return talbe[i]
            }
        },
        getLowestChildren(children, sums) {
            for (const child of children) {
                if (child.type) {
                    if (child.hasOwnProperty('form_child_list') && child.form_child_list && child.form_child_list.length > 0) {
                        this.getLowestChildren(child.form_child_list, sums)
                    } else if (child.hasOwnProperty('child_list') && child.child_list && child.child_list.length > 0) {
                        this.getLowestChildren(child.child_list, sums)
                    } else if (child.hasOwnProperty('spf_list') && child.spf_list && child.spf_list.length > 0) {
                        this.getLowestChildren(child.spf_list, sums)
                    }
                } else {
                    for (const f of this.calcFlds) {

                        if (!child[f]) {
                            sums[f] += 0
                        } else {
                            sums[f] += child[f]
                        }
                    }
                }
            }
        },
        sumOfChildren(parent, type) {
            let parent_data
            if (type === 'obligation') {
                parent_data = this.getTableParent(this.budgetFormObligationData, 'id', parent.id)
            } else if (type === 'payment') {
                parent_data = this.getTableParent(this.budgetFormObligationData, 'id', parent.id)
            }
            const sums = {}
            for (const f of this.calcFlds) {
                sums[f] = 0
            }
            this.getLowestChildren(parent_data.child_list, sums)
            return sums
        },

        toggleShowObligation() {
            this.showObligation = !this.showObligation;
        },

        toggleShowPayment() {
            this.showPayment = !this.showPayment;
        },

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        inputFixed(item, field, value) {
            if (value) {
                this.$set(item, field, parseFloat(value));
            } else {
                this.$set(item, field, 0);
            }
        },

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            if (!event.target.value) {
                event.target.value = 0;
            }
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');

            const regex = new RegExp(pattern);
            const charCode = event.keyCode;
            const key = String.fromCharCode(charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            return true;
        }, // вводит по заданному паттерну

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined' || isNaN(value) || isFinite(value) || (value === null)) {
                return 0;
            }
        },

        setNameLang(obj, codeName) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) {
                txt = obj.name_ru;
            }
            if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
                if (obj[codeName]) {
                    txt = obj[codeName] + ' - ' + txt;
                }
            }
            const el = Object.assign({}, obj);
            el.name = txt;
            return el;
        },

        setIdArr(arr, codeName) {
            const result = [];
            if (arr && arr.length > 0) {
                for (const el of arr) {
                    if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                        result[result.length - 1].idArr.push(el.id);
                    } else {
                        result.push(Object.assign({idArr: [el.id]}, el));
                    }
                }
            }
            return result;
        },
        getDownloadFilter() {
            const filter = {
                region: this.curReg.code,
                year: this.repYear
            }
            if (this.curAbp & this.curAbp.hasOwnProperty('code') & this.curAbp.code) {
                filter.abp = this.curAbp.code
                if (this.curProg & this.curProg.hasOwnProperty('code') & this.curProg.code) {
                    filter.prg = this.curProg.code
                    if (this.curSubProg & this.curSubProg.hasOwnProperty('code') & this.curSubProg.code) {
                        filter.ppr = this.curSubProg.code
                    }
                }
            }
            return filter
        },
        downloadRepSpfPayments() {
            const filter = this.getDownloadFilter()
            Ax(
                {
                    url: '/api-py/get-budget-execution-spf-report/' + JSON.stringify(filter) + '/16/payment',
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Сводный план финансирования по платежам.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRepSpfPayments()', error.toString());
                }
            );
        },

        downloadRepSpfPayments17() {
            const filter = this.getDownloadFilter()
            Ax(
                {
                    url: '/api-py/get-budget-execution-spf-report/' + JSON.stringify(filter) + '/17/payment',
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Сводный план финансирования по платежам.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRepSpfPayments17()', error.toString());
                }
            );
        },

        downloadRepSpfObligations() {
            const filter = this.getDownloadFilter()
            Ax(
                {
                    url: '/api-py/get-budget-execution-spf-report/' + JSON.stringify(filter) + '/18/obligation',
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Сводный план финансирования по обязательствам.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRepSpfObligations()', error.toString());
                }
            );
        },

        downloadRepSpfObligations19() {
            const filter = this.getDownloadFilter()
            Ax(
                {
                    url: '/api-py/get-budget-execution-spf-report/' + JSON.stringify(filter) + '/19/obligation',
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Сводный план финансирования по обязательствам.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRepSpfObligations19()', error.toString());
                }
            );
        }
    },
    computed: {
        usrId() {
            if (store.state.user.sub === undefined) {
                return null;
            }
            return store.state.user.sub;
        },
        abp() {
            const res = [];
            for (const el of this.abpBase) {
                res.push(this.setNameLang(el, 'abp'));
            }

            if (this.curAbp !== null) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.curAbp = this.setNameLang(this.curAbp, 'abp');
            }
            return res;
        },
        regions() {
            const res = [];
            if (this.regBase) {
                for (const el of this.regBase) {
                    res.push(this.setNameLang(el, 'code'));
                }
            }
            if (this.curReg !== null) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.curReg = this.setNameLang(this.curReg, 'code');
            }
            return res;
        },
        prog(){
            const res = [];
            for (const el of this.progBase) {
                res.push(this.setNameLang(el, 'prg'));
            }
            if (this.curProg !== null) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.curProg = this.setNameLang(this.curProg, 'prg');
            }
            return res;
        },
        subProg() {
            const res = [];
            for (const el of this.subProgBase) {
                res.push(this.setNameLang(el, 'ppr'));
            }
            if (this.curSubProg !== null) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
            }
            return res;
        },
        spf() {
            const res = [];
            for (const el of this.spfBase) {
                res.push(this.setNameLang(el, 'spf'));
            }

            if (this.curSpf !== null) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.curSpf = this.setNameLang(this.curSpf, 'spf');
            }
            return res;
        }




    }
};
</script>

<style>
.icon-btn{
    width: 20px;
    height: 20px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none !important;
    background-color: transparent;
    border: 1px solid rgba(28, 42, 62, 1);
    border-radius: 50%;
    margin: 0;
}
.icon-btn i{
    font-size: 0.4444444444rem;
    color: rgba(28, 42, 62, 1);
}
</style>
