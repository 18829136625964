








































import { Component, Prop, Vue } from 'vue-property-decorator';
import CAbpTableForFunctional from '@/modules/budget/execution/form/tabs/components/AbpTableForFunctional.vue';
import axios from "axios";

@Component({
    components: {
        'c-abp-table-for-functional': CAbpTableForFunctional
    }
})
export default class CInsideWindow extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private filter!: any;

    private progress: number | null = null;

    private showObligation = true;
    private showPayment = true;

    // private curSpfs = [];
    // private obligationData: any = [];
    // private paymentData: any = [];
    private isLoad = false;
    private isSuccess = false;

    private async created() {
        this.$watch('filter', await this.chgFilter);
    }

    private beforeUpdate() {
        this.budgetFormObligation.forEach((row: any) => {
            row._rowVariant = 'info';
        });
        this.budgetFormPayment.forEach((row: any) => {
            row._rowVariant = 'info';
        });
    }

    // private async created() {
    //     this.progress = 10;
    //     this.$watch('filter', await this.chgFilter);
    // }
    private async chgFilter() {
        this.progress = 10;
        this.$emit('chgLoad', true);
        this.budgetFormObligation.splice(0);
        this.budgetFormPayment.splice(0);
        // await this.loadDataForObligation();
        // await this.loadDataForPayment();
        await this.prepareFormObligation();
        this.progress = 50;
        await this.prepareFormPayment();
        this.progress = 100;
        this.$emit('chgLoad', false);
    }

    private budgetFormObligation: any = [];
    private budgetFormPayment: any = [];

    private calcFlds =  [
        'plan',
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ];



    private async prepareFormObligation() {
        this.budgetFormObligation.splice(0);
        const dataOblig = await this.getData('obligation');
        if (dataOblig && dataOblig.length > 0) {
            for (const item of dataOblig) {
                this.createTable(item, 0, 'obligation', true);
            }
        }
        this.budgetFormObligation.forEach((el: any, ind: number) => {
            this.templateData(el);
        });
    } // подготовка отображения данных обязательств

    private async prepareFormPayment() {
        this.budgetFormPayment.splice(0);
        const dataPayment = await this.getData('payment');
        if (dataPayment && dataPayment.length > 0) {
            for (const item of dataPayment) {
                this.createTable(item, 0, 'payment', true);
            }
        }
        this.budgetFormPayment.forEach((el: any, ind: number) => {
            this.templateData(el);
        });
    } // подготовка отображения данных платежей

    private templateData(dataItem: any) {
        Object.defineProperty(dataItem, 'deviation', {
            get: function () {
                const sum = (parseFloat(dataItem.january) + parseFloat(dataItem.february) + parseFloat(dataItem.march)
                    + parseFloat(dataItem.april) + parseFloat(dataItem.may) + parseFloat(dataItem.june)
                    + parseFloat(dataItem.july) + parseFloat(dataItem.august) + parseFloat(dataItem.september)
                    + parseFloat(dataItem.october) + parseFloat(dataItem.november) + parseFloat(dataItem.december));
                return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
            }
        });
    }

    private createTable(elem: any, parent_id: any, typeTable: string, isMain: boolean = false) {
        const that = this;
        // создание таблицы на основе дерева
        const item = Object.assign({}, elem);

        this.$set(item, 'parent_id', parent_id);
        this.$set(item, 'visible', isMain);
        Object.defineProperty(item, 'parent', {
            get: function () {
                if (typeTable === 'obligation') {
                    for (const row of that.budgetFormObligation) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                    return null;
                } else if (typeTable === 'payment') {
                    for (const row of that.budgetFormPayment) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                    return null;
                }
            }
        });

        if (item.hasOwnProperty('type')) {
            this.$set(item, 'open', false);
            this.$set(item, 'hasChild', true);

            this.calcFlds.forEach(field => {
                Object.defineProperty(item, field, {
                    get: function () {
                        return that.reSum(item, field, typeTable);
                    }
                });
            });
        } else if (!item.hasOwnProperty('child_list')) {
            this.calcFlds.forEach((field) => {
                if (item.hasOwnProperty(field)) {
                    if (!item[field]) {
                        this.$set(item, field, 0);
                    } else {
                        this.$set(item, field, parseFloat(item[field]));
                    }
                }
            });
        } else {
            this.$set(item, 'open', false);
            this.$set(item, 'hasChild', true);
        }

        if (item) {
            if (typeTable === 'obligation') {
                this.$set(item, 'index', this.budgetFormObligation.length);
                this.$set(this.budgetFormObligation, this.budgetFormObligation.length, item);
            } else if (typeTable === 'payment') {
                this.$set(item, 'index', this.budgetFormPayment.length);
                this.$set(this.budgetFormPayment, this.budgetFormPayment.length, item);
            }
        }

        if (item.hasOwnProperty('hasChild')) {
            if (item.hasOwnProperty('form_child_list') && item.form_child_list && item.form_child_list.length > 0) {
                for (const ch of item.form_child_list) {
                    this.createTable(ch, item.id, typeTable);
                }
                item.form_child_list = [];
            } else if (item.hasOwnProperty('child_list') && item.child_list && item.child_list.length > 0) {
                for (const ch of item.child_list) {
                    this.createTable(ch, item.id, typeTable);
                }
                delete item.child_list;
            } else if (item.hasOwnProperty('spf_list') && item.spf_list && item.spf_list.length > 0) {
                for (const ch of item.spf_list) {
                    this.createTable(ch, item.id, typeTable);
                }
                delete item.spf_list;
            }
        }
    }// древовидную выборку преобразовывает в таблицу (для отображения)

    private reSum(parent: any, field: string, typeTable: string) {
        let sum = 0;
        if (typeTable === 'obligation') {
            this.budgetFormObligation.forEach((row: any) => {
                if ((row.parent_id === parent.id)) {
                    if (!row[field]) {
                        sum += 0;
                    } else {
                        sum += parseFloat(row[field]);
                    }
                    // sum += parseFloat(row[field]);
                }
            });
        } else if (typeTable === 'payment') {
            this.budgetFormPayment.forEach((row: any) => {
                if ((row.parent_id === parent.id)) {
                    if (!row[field]) {
                        sum += 0;
                    } else {
                        sum += parseFloat(row[field]);
                    }
                    // sum += parseFloat(row[field]);
                }
            });
        }

        return Math.round(sum);
    } // пересчет суммы

    private async getData(type: string) {
        let values = [];
        try {
            let response: any = [];
            const queryString = {...this.filter, type: type};
            response = await axios.get('/api/budget-execution/pf', {params: queryString});
            response = response.data;
            values = await response;

            if (values.length === 0) {
                this.$emit('chgLoad', false);
                return;
            }

            // eslint-disable-next-line consistent-return
            return values;
        } catch (error) {
            this.$emit('chgLoad', false);
            this.makeToast('danger', `Ошибка запроса ${type} in loadData()`, error.toString());
        }
    }

    private toggleShowObligation() {
        console.log(this.showObligation)
        this.showObligation = !this.showObligation;
    }

    private toggleShowPayment() {
        console.log(this.showPayment)
        this.showPayment = !this.showPayment;
    }

    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
