<template>
    <div>
        <div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!---------------------год------------------------->
                            <div class="filter-block">
                                <b-form-group label="Год">
                                    <b-form-input
                                        type="number"
                                        v-model="repYear"
                                        ref="yearRef"
                                    ></b-form-input>
                                </b-form-group>
                            </div>

                            <!---------------------регион------------------------->
                            <div class="filter-block">
                                <b-form-group label="Область/район">
                                    <b-form-select
                                        v-model="reg"
                                        :options="listReg"
                                        value-field="code"
                                        ref="regionRef"
                                    ></b-form-select>
                                </b-form-group>
                            </div>

                            <div class="filter-block">
                                <b-button variant="primary" @click="chgData">Открыть</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
            </div>
            <div class="filter-breadcrumb">
                    <span class="item-breadcrumb" @click="openFilterByRef('yearRef')">
                        {{ repYear }}
                    </span>
                <span class="item-breadcrumb" @click="openFilterByRef('regionRef')">
                    {{ listReg.find(x => x.code === reg).text }}
                </span>
            </div>
        </div>

        <div>
            <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
            <div class="table-card">
                <b-table
                    :fields="tableFields"
                    :items="budgetForm"
                    responsive="true"
                    bordered
                    head-variant="light"
                    sticky-header="true"
                    no-border-collapse
                    :filter="filter"
                    :filter-function="filterFunction"
                >
                    <template #head(name_ru)="scope">
                        <div>Месяц</div>
                        <b-form-input
                            id="filter-input"
                            v-model="filter.name_ru"
                            type="search"
                        ></b-form-input>
                    </template>
                    <template #cell(action)="data">
                        <div></div>
                    </template>
                    <template #cell(name_ru)="data">
                        <div class="text-left">
                            {{ data.item.name_ru }}
                        </div>
                    </template>
                    <template #cell(spp_income)="data">
                        <div class="text-right">
                            {{ $n(data.item.spp_income) }}
                        </div>
                    </template>
                    <template #cell(ipf_payment)="data">
                        <div class="text-right">
                            {{ $n(data.item.ipf_payment) }}
                        </div>
                    </template>
                    <template #cell(difference)="data">
                        <div class="text-right"
                             :class="{ 'error-deviation': (parseFloat(data.item.difference) !== 0) }">
                            {{ $n(data.item.difference) }}
                        </div>
                    </template>
                    <template #cell(cumulative_difference)="data">
                        <div class="text-right"
                             :class="{ 'error-deviation': (parseFloat(data.item.cumulative_difference) !== 0) }">
                            {{ $n(data.item.cumulative_difference) }}
                        </div>
                    </template>
                    <template #bottom-row="data">
                        <td></td>
                        <td class="text-left font-weight-bold">Итого (тыс. тенге)</td>
                        <td class="text-right font-weight-bold">{{ $n(total('spp_income')) }}</td>
                        <td class="text-right font-weight-bold">{{ $n(total('ipf_payment')) }}</td>
                        <td></td>
                        <td class="text-right font-weight-bold">{{ $n(budgetForm[budgetForm.length-1]['cumulative_difference']) }}</td>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>

<script>
import store from '@/services/store';
import {Ax} from '@/utils';

export default {
    name: "BudgetExecutionBalancing",
    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'name_ru',
                    label: 'Месяц'
                },
                {
                    key: 'spp_income',
                    label: 'Доходы'
                },
                {
                    key: 'ipf_payment',
                    label: 'Расходы'
                },
                {
                    key: 'difference',
                    label: 'Разница (помесячно)'
                },
                {
                    key: 'cumulative_difference',
                    label: 'Разница (на период)'
                }
            ],


            repYear: 0,
            obl: 0,
            listReg: null,
            reg: null,

            months: [
                {
                    name_en: 'january',
                    name_ru: 'Январь'
                }, {
                    name_en: 'february',
                    name_ru: 'Февраль'
                },
                {
                    name_en: 'march',
                    name_ru: 'Март'
                }, {
                    name_en: 'april',
                    name_ru: 'Апрель'
                }, {
                    name_en: 'may',
                    name_ru: 'Май'
                },
                {
                    name_en: 'june',
                    name_ru: 'Июнь'
                }, {
                    name_en: 'july',
                    name_ru: 'Июль'
                }, {
                    name_en: 'august',
                    name_ru: 'Август'
                }, {
                    name_en: 'september',
                    name_ru: 'Сентябрь'
                }, {
                    name_en: 'october',
                    name_ru: 'Октябрь'
                }, {
                    name_en: 'november',
                    name_ru: 'Ноябрь'
                }, {
                    name_en: 'december',
                    name_ru: 'Декабрь'
                }
            ],

            filter: {
                name_ru: null
            },

            filtered: false,

            firstData: [],
            budgetForm: [],
            bar: 0
        };
    },

    created() {
        const date = new Date();
        this.repYear = date.getFullYear();
    },

    beforeUpdate() {
        this.budgetForm.forEach(row => {
            row._rowVariant = 'primary';
        });
    },


    async mounted() {
        await this.getObl();
        await this.loadData();
        this.bar = 100;
    },

    methods: {
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                        this.reg = json.region;
                    });
                this.bar = 20;
                await this.loadRegions();
            } catch (error) {
                this.bar = 100;
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + store.state.user.sub);
                this.listReg = await response.json();
                this.bar = 40;
            } catch (error) {
                this.bar = 100;
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов

        async loadData() {
            try {
                this.firstData = null;
                this.bar = 60;
                const response = await fetch('/api-py/budget-execution-balancing/' + this.repYear + '/' + this.reg);
                this.firstData = await response.json();
                this.bar = 80;
                this.prepareData();
            } catch (error) {
                this.bar = 100;
                this.makeToast('danger', 'Ошибка запроса loadData', error.toString());
            }
        },

        prepareData() {
            this.budgetForm.splice(0);
            this.months.forEach(month => {
                const itemData = this.firstData[month.name_en];
                itemData['name_ru'] = month.name_ru;
                itemData['name_en'] = month.name_en;
                this.budgetForm.push(itemData);
            });
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        total(field) {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row[field]);
            }
            return parseFloat(sum.toFixed(2));
        }, // итого по заданному полю

        async chgData() {
            this.$refs.drop.hide(true);

            await this.loadData();
            this.bar = 100;
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        //= ========== фильтрация данных в столбцах ===============

        filterFunction(row, val) {
            const { name_ru } = val;
            return [
                !name_ru || row.name_ru.toLowerCase().indexOf(name_ru.toLowerCase()) !== -1
            ].every(Boolean);
        },

        downloadRepBalancing() {
            const param = {
                year: this.repYear,
                region_id: this.reg
            };
            Ax(
                {
                    url: '/api-py/download-budget-execution-balancing/' + JSON.stringify(param),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Сводный баланс.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRepBalancing()', error.toString());
                }
            );
        }
    }
};
</script>

<style scoped>
.error-deviation {
    color: #C0504C;
}
</style>
