
















































































import {Component, Vue, Prop} from 'vue-property-decorator';
import store from '@/services/store';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';


@Component({
    components: {}
})
export default class IpfAddItemModal extends Vue {

    @Prop({
        required: true,
        default: false
    })
    private show!: any;

    @Prop({
        required: false,
        default: (new Date()).getFullYear()
    })
    private filterYear!: any;

    @Prop({
        required: false,
        default: []
    })
    private dataTablePayment!: any[];

    @Prop({
        required: false,
        default: []
    })
    private dataTableObligation!: any[];


    @Prop({
        required: true,
        default: null
    })
    private curGr!: any;

    @Prop({
        required: true,
        default: null
    })
    private curAbp!: any;

    @Prop({
        required: true,
        default: null
    })
    private curGu!: any;

    @Prop({
        required: false,
        default: null
    })
    private filterPrg!: any;

    @Prop({
        required: false,
        default: null
    })
    private filterPpr!: any;

    @Prop({
        required: false,
        default: null
    })
    private filterSpf!: any;

    @Prop({
        required: false,
        default: false
    })
    private isEmptyTotal!: any;


    private showModal = false;

    // ----------------------budgetLevel-----------------




    private get is_ready_to_add() {
        /*if (this.curProg !== null) {
            if (this.curProg.develop_type === 1) {
                if (this.curBip === null) {
                    return false;
                }
            }
        }*/
        return true;
    }

    private get usrId() {
        if (store.state.user.sub === undefined) {
            return null;
        }
        return store.state.user.sub;
    }

    // ----------------Программа
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) {
            this.curProg = this.setNameLang(this.curProg, 'prg');
        }
        return res;
    }

    private chgProg() {
        this.curSubProg = null
        this.subProgBase = [];
        this.curSpf = [];
        this.spfBase = []
        this.loadSubProg();
    }

    private chgSubPrg() {
        this.loadSpf()
    }

    private chgSpf() {
        // this.loadBip()
    }

    private async loadProg() {
        let response: any = [];
        this.progBase = [];
        if (this.curAbp !== null) {
            try {
                const path = !this.isEmptyTotal? `/api/budget-execution-pf-ipf/prg?year=${this.filterYear}&gu=${this.curGu}`
                    : `/api-py/get_prg_by_abp/${this.curAbp}/${this.filterYear}`
                response = await fetch(path)
                    .then(response => response.json());
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки программ', (error as Error).toString());
            }
        }


        response.sort((a: any, b: any) => (a.prg - b.prg > 0) ? 1 : -1);
        this.progBase = this.setIdArr(response, 'prg');

        if (this.progBase.length > 0) {
            this.curProg = this.progBase[0];
            const filEl = this.filterPrg
            if ((filEl !== undefined && filEl !== null) && filEl) {
                this.curProg = this.setNameLang(this.progBase.find(obj => obj.prg === filEl), 'prg')
                this.progBase = [this.curProg]
            }

        } else {
            this.curProg = null;
        }
        this.chgProg();
    }

    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) {
            this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
        }
        return res;
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];
        if (this.curProg !== null) {
            try {
                response = await fetch(`/api-py/get_ppr_by_abp_prg/${this.curAbp}/${this.curProg.prg}/${this.filterYear}`).then(response => response.json());
            } catch (error) {
                this.makeToast('danger', 'Ошибка загрузки подпрограмм', (error as Error).toString());
                response = [];
            }
        }

        response.sort((a: any, b: any) => (a.ppr - b.ppr > 0) ? 1 : -1);
        // eslint-disable-next-line @typescript-eslint/camelcase
        // if (response.length) {
        //     response.unshift({name_ru: 'Все'});
        // }
        this.subProgBase = this.setIdArr(response, 'ppr');

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
            const filEl = this.filterPpr
            if ((filEl !== undefined && filEl !== null) && filEl) {
                this.curSubProg = this.setNameLang(this.subProgBase.find(obj => obj.ppr === filEl), 'ppr')
                this.subProgBase = [this.curSubProg]
            }
            this.chgSubPrg()
        } else {
            this.curSubProg = null;
            this.chgSubPrg()
        }
    }

    // --- spf
    private spfBase: any[] = [];
    private curSpf: any[] | null = null;

    private get spfDict(): any[] {
        const result: any[] = [];
        for (const el of this.spfBase) {
            result.push(this.setNameLang(el, 'spf'));
        }
        return result;
    }

    private async loadSpf() {
        this.spfBase = [];
        this.curSpf = [];
        try {
            const response = await fetch('/api-py/get-spf-list/' + this.filterYear)
                .then(response => response.json());
            this.spfBase = response.list;
        } catch (error) {
            makeToast(this, 'danger', 'Ошибка загрузки loadSpfList', error.toString());
        }
        if (this.spfDict.length) {
            this.curSpf = [];
            const filEl = this.filterSpf
            if ((filEl !== undefined && filEl !== null) && filEl) {
                this.curSpf = [this.setNameLang(this.spfBase.find(obj => obj.spf === filEl), 'spf')];
                this.spfBase = this.curSpf
            }
        }
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private mounted() {
        this.loadProg()
        const that = this;
        this.$watch('show', function () {
            if (that.show) {
                that.showModal = that.show;
            }
            that.$emit('shown');
        });
        this.$watch('filterPrg', function () {
            this.loadProg()
        });
        this.$watch('filterPpr', function () {
            this.loadSubProg()
        });
        this.$watch('filterSpf', function () {
            this.loadSpf()
        });
        this.$watch('isEmptyTotal', function () {
            this.loadProg()
        });
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        for (const el of arr) {
            if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                result[result.length - 1].idArr.push(el.id);
            } else {
                result.push(Object.assign({idArr: [el.id]}, el));
            }
        }
        return result;
    }
    private addClk() {
        if (this.curSpf === null || !this.curSpf) {
            makeToast(this, 'warning', 'Добавление элемента', 'Выберите специфику!');
            return;
        }
        let curPpr = null;
        let curPprName = null;
        let curGr = this.curProg.gr;
        if (this.curSubProg) {
            curPpr =  this.curSubProg.ppr;
            curPprName =  this.curSubProg.name_ru;
            curGr = this.curSubProg.gr;
        }
        // cahnge
        const addItems: any[] = [];
        const existingSpfs: any[] = [];
        for (const curSpf of this.curSpf) {
            let existPayment = false;
            let existOblig = false;
            for (let i =0; i < this.dataTablePayment.length; i++)   {
                const el = this.dataTablePayment[i];
                if (el.abp === this.curAbp && el.prg === this.curProg.prg && el.ppr === curPpr && el.spf === curSpf.spf) {
                    existPayment = true;
                }
            }
            for (let i =0; i < this.dataTableObligation.length; i++) {
                const el = this.dataTableObligation[i];
                if (el.abp === this.curAbp && el.prg === this.curProg.prg && el.ppr === curPpr && el.spf === curSpf.spf) {
                    existOblig = true;
                }
            }
            if (existPayment && existOblig) {
                existingSpfs.push(curSpf.spf)
                makeToast(this, 'warning', 'Добавление элемента', 'Элемент уже существует!' + `\n${curSpf.spf} - ${curSpf.name_ru}`);
            } else {
                const resObj: any = {
                    abp: this.curAbp,
                    gr: curGr,
                    abp_name: this.curAbp,
                    prg: this.curProg.prg,
                    prg_name: this.curProg.name_ru,
                    ppr: curPpr,
                    ppr_name: curPprName,
                    spf: curSpf.spf,
                    name_ru: curSpf.name_ru
                };
                for (let m = 0; m < 12; m++) {
                    resObj[`month${m}`] = 0;
                }
                addItems.push(resObj)
            }
        }

        if (existingSpfs.length && existingSpfs.length === this.curSpf.length) return

        this.$emit('addItemClk', addItems);
        this.showModal = false;
        this.resetAddItemModal()
    }
    private resetAddItemModal() {
        this.curProg = this.prog[0]
        this.chgProg()
    }

    private addSpec(data: any) {
        const body = { data: data, type: 'payment' }
        this.$emit('addSpec', body)
    }

    private deleteSpec(data: any) {
        const body = { data: data, type: 'payment' }
        this.$emit('deleteSpec', body)
    }

}
