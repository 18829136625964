








































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from "@/services/store";

@Component
export default class CAbpWindow extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private filter!: any;

    @Prop({
        required: true,
        default: null
    })
    private nameAbp!: string;

    @Prop({
        required: true,
        default: null
    })
    private editAccess!: boolean;

    private progress: number | null = null;

    private tableFields = [
        {
            key: 'action',
            label: ' '
        },
        {
            key: 'name_ru',
            label: 'Наименование'
        },
        {
            key: 'defective',
            label: 'Дефектная ведомость'
        },
        {
            key: 'status',
            label: 'Статус'
        },
        {
            key: 'executor',
            label: 'Исполнитель'
        },
        {
            key: 'date_creation',
            label: 'Дата создания'
        },
        {
            key: 'comment',
            label: 'комментарий'
        },
        {
            key: 'more',
            label: ''
        }
    ];

    private modalTableFields = [
        {
            key: 'number',
            label: '№'
        },
        {
            key: 'code',
            label: 'Код ГУ'
        },
        {
            key: 'name_ru',
            label: 'Наименование'
        },
        {
            key: 'status',
            label: 'Статус'
        }
    ];

    private abpData: any = [];
    private guList: any = [];
    private verifiedStatuses = 0;

    // private async created() {
    //     this.progress = 10;
    //     await this.chgFilter();
    // }

    @Watch('filter')
    async onPropertyChanged(value: string, oldValue: string) {
        await this.chgFilter();
    }

    private async chgFilter() {
        await this.loadData();
        await this.loadCompany();
    }

    private get usrId() {
        if (store.state.user.sub === undefined) { return null; }
        return store.state.user.sub;
    }

    private async loadCompany() {
        this.guList = [];
        this.verifiedStatuses = 0;
        let result = [];
        try {
            result = await fetch(`/api-py/all-gu-by-region-abp-user-id/${this.filter.region}/${this.filter.abp}/${this.usrId}`)
                .then(response => response.json());
            this.progress = 75;
            result.forEach((el: any, ind: number) => {
                const item: any = {
                    number: ind + 1,
                    code: el.code,
                    name_ru: el.name_ru,
                    name_kk: el.name_kk,
                    status: 'Отсутствует'
                };
                this.guList.push(item);
            });
        } catch (error) {
            this.progress = 100;
            this.makeToast('danger', 'Ошибка запроса loadCompany1()', error.toString());
        }

        let existingGu: any = [];
        try {
            existingGu = await fetch('/api-py/get-budget-execution-gu-list/' + JSON.stringify(this.filter))
                .then(response => response.json());
            this.progress = 100;
            this.guList.forEach((el: any) => {
                existingGu.forEach((item: any) => {
                    if (el.code === item.gu) {
                        this.verifiedStatuses++;
                        el.status = item.defective;
                    }
                });
            });
        } catch (error) {
            this.progress = 100;
            this.makeToast('danger', 'Ошибка запроса loadCompany2()', error.toString());
        }
    }

    private loadData() {
        this.abpData.splice(0);
        const dataItem: any = {
            id: 0,
            name_ru: `План финансирования на ${this.filter.year} год по АБП «${this.nameAbp}»`,
            abp: this.filter.abp,
            year: this.filter.year,
            defective: `Получено ${this.verifiedStatuses} из ${this.guList.length}`,
            status: 'Создан',
            executor: '',
            date_creation: '',
            comment: 'Комментарий'
        };
        this.abpData.push(dataItem);
        this.progress = 40;

        // let values = [];
        // try {
        //     this.progress = 50;
        //     const response = await fetch('/api-py/get-budget-execution-ipf/' + JSON.stringify(this.filter) + '/ipf');
        //     values = await response.json();
        //     this.progress = 75;
        //
        //     if (values.length === 0) {
        //         this.progress = 100;
        //         return;
        //     }
        //     // eslint-disable-next-line consistent-return
        // } catch (error) {
        //     this.progress = 0;
        //     this.makeToast('danger', 'Ошибка запроса loadData()', error.toString());
        // }
        //
        // if (values && values.length > 0) {
        //     values.forEach((val: any) => {
        //         let itemExist = [];
        //         itemExist = this.abpData.filter(function (item: any) {
        //             if (item.gu === val.gu) {
        //                 return item;
        //             }
        //         });
        //         if (itemExist.length > 0) {
        //             itemExist[0].id = val.id;
        //             // itemExist[0].gu = val.gu;
        //             // itemExist[0].year = val.year;
        //             itemExist[0].defective = val.defective;
        //             // status: 'Создан',
        //             // comment: 'Комментарий'
        //         }
        //     });
        // }
        // this.progress = 100;
    }

    private toggleOpenWindowBool() {
        this.$emit('toggleOpenWindowBool', this.abpData);
    }

    private openModal() {
        const modal: any = this.$refs.ipfList;
        modal.show();
    }

    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
