<template>
    <div>
        <div>
            <div class="filter-container">
                <div class="left-content">
                    <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                        <template #button-content>
                            <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                            class="icon icon-keyboard"></i></span>
                        </template>
                        <div>
                            <div class="top-content">
                                <span>Параметры фильтра</span>
                                <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                            </div>
                            <!---------------------год------------------------->
                            <div class="filter-block">
                                <b-form-group label="Год">
                                    <b-form-input
                                        type="number"
                                        v-model="repYear"
                                        ref="yearRef"
                                        @input="loadRepFilterData"
                                    ></b-form-input>
                                </b-form-group>
                            </div>

                            <!---------------------регион------------------------->
                            <div class="filter-block">
                                <b-form-group label="Регион">
                                    <multiselect
                                        v-if="listReg"
                                        v-model="reg"
                                        track-by="code"
                                        label="name"
                                        placeholder="Регион"
                                        :options="listReg"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="loadRepFilterData"
                                    >
                                    </multiselect>
                                    <span v-else class="input-style"></span>
                                </b-form-group>
                            </div>

                            <div class="filter-block">
                                <b-form-group label="Категория">
                                    <multiselect
                                        v-model="repKat"
                                        track-by="code"
                                        label="name_ru"
                                        placeholder="Выбрать категорию"
                                        :options="repKatOpt"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgRepKat"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <div class="filter-block">
                                <b-form-group label="Класс">
                                    <multiselect
                                        v-model="repCls"
                                        track-by="code"
                                        label="name_ru"
                                        placeholder="Выбрать класс"
                                        :options="repClsOpt"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgRepCls"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <div class="filter-block">
                                <b-form-group label="Подкласс">
                                    <multiselect
                                        v-model="repSubCls"
                                        track-by="code"
                                        label="name_ru"
                                        placeholder="Выбрать Подкласс"
                                        :options="repSubClsOpt"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgRepSubCls"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <div class="filter-block">
                                <b-form-group label="Специфика">
                                    <multiselect
                                        v-model="repSpec"
                                        track-by="code"
                                        label="name_ru"
                                        placeholder="Выбрать специфику"
                                        :options="repSpecOpt"
                                        :searchable="true"
                                        :allow-empty="false"
                                        :show-labels="false"
                                        @input="chgRepSpec"
                                    >
                                    </multiselect>
                                </b-form-group>
                            </div>

                            <div class="filter-block">
                                <b-button variant="primary" @click="chgData">Открыть</b-button>
                            </div>
                        </div>
                    </b-dropdown>
                </div>
                <div class="filter-actions">
                    <b-button v-if="haveEditAccess()" variant="success" :disabled="isLoad" @click="prepareForSave">Сохранить</b-button>
                    <b-button v-if="haveEditAccess()" variant="primary" @click="signAndSend">Подписать</b-button>
                </div>
            </div>
            <div class="filter-breadcrumb">
                <span class="item-breadcrumb" @click="openFilterByRef('yearRef')">
                    {{ repYear }}
                </span>
                <span v-if="reg && reg.hasOwnProperty('code') && reg.code" class="item-breadcrumb" @click="openFilterByRef('regionRef')">
                    {{ reg.code }}-{{ reg.name_ru }}
                </span>
                <span v-if="repKat && repKat.hasOwnProperty('code') && repKat.code" class="item-breadcrumb" @click="openFilterByRef('regionRef')">
                    {{ repKat.code }}-КАТ
                </span>
                <span v-if="repCls && repCls.hasOwnProperty('code') && repCls.code" class="item-breadcrumb" @click="openFilterByRef('regionRef')">
                    {{ repCls.code }}-КЛ
                </span>
                <span v-if="repSubCls && repSubCls.hasOwnProperty('code') && repSubCls.code" class="item-breadcrumb" @click="openFilterByRef('regionRef')">
                    {{ repSubCls.code }}-ПКЛ
                </span>
                <span v-if="repSpec && repSpec.hasOwnProperty('code') && repSpec.code" class="item-breadcrumb" @click="openFilterByRef('regionRef')">
                    {{ repSpec.code }}-СПП
                </span>

            </div>
        </div>

        <div>
            <a v-if="haveEditAccess()" class="badge badge-primary" style="position: absolute; z-index: 1; top: 15px; right: 21px;cursor: pointer" @click=" showAddItemModal = !showAddItemModal">   добавить +   </a>
            <b-progress variant="primary" v-show="bar<100" height="3px" :value="bar" striped animated></b-progress>
            <div class="table-card table-container b-table-sticky-header">
                <table class="table b-table table-hover table-bordered table-sm b-table-no-border-collapse">
                    <thead>
                        <tr>
                            <th v-for="tname in tableFields" :key="tname.key">
                                <template v-if="tname.key === 'action'">
                                    <b-button @click="openAll()">
                                        <i class="icon icon-keyboard icon-rotate-270" v-if="open"></i>
                                        <i class="icon icon-keyboard icon-rotate-90" v-if="!open"></i>
                                    </b-button>
                                </template>
                                <template v-else>
                                    {{ tname.label }}
                                </template>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(data, index) in budgetForm" :key="index"
                            :class="{
                            'is-hidden': !data.visible,
                            '': data.visible,
                            'table-info': data.type === 1,
                            'pr-level': data.type === 2,
                            'ppr-level': data.type === 3
                            }"
                        >
                            <template>
                                <td class="text-right"  v-if="data.type === 1">
                                    <b-button @click="openChilds(data)">
                                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.open"></i>
                                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.open"></i>
                                    </b-button>
                                </td>
                                <td colspan="2" class="text-right"  v-else-if="data.type === 2">
                                    <b-button @click="openChilds(data)">
                                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.open"></i>
                                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.open"></i>
                                    </b-button>
                                </td>
                                <td colspan="3" class="text-right"  v-else-if="data.type === 3">
                                    <b-button @click="openChilds(data)">
                                        <i class="icon icon-keyboard icon-rotate-270" v-if="data.open"></i>
                                        <i class="icon icon-keyboard icon-rotate-90" v-if="!data.open"></i>
                                    </b-button>
                                </td>
                                <td v-else colspan="4"></td>
                            </template>
                            <template>
                                <td colspan="4" v-if="data.type === 1">
                                <div v-if="data.type === 1">
                                    {{ data.kat }} - {{ data.name_ru }}
                                </div>
                                </td>
                            </template>
                            <template>
                                <td colspan="3" v-if="data.type === 2">
                                    <div v-if="data.type === 2">
                                        {{ data.clss > 9 ? data.clss : "0" + data.clss }} - {{ data.name_ru }}
                                    </div>
                                </td>
                            </template>
                            <template>
                                <td colspan="2" v-if="data.type === 3">
                                    <div v-if="data.type === 3">
                                        {{ data.pcl }} - {{ data.name_ru }}
                                    </div>
                                </td>
                            </template>
                            <template>
                                <td colspan="1" v-if="data.type === 5">
                                    <div
                                        title="Разноска годовой суммы бюджета по месяцам"
                                        style="cursor:pointer;"
                                        v-if="(haveEditAccess()) && data.income_data > 0"
                                        variant="light"
                                        @click="openModal(data)">
                                        <span>{{ data.spf > 9 ? data.spf : "0" + data.spf }} - {{ data.name_ru }} <img width="20px" height="20px" title=""
                                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAABmJLR0QA/wD/AP+gvaeTAAAC4ElEQVR4nO3dzW4TMRiF4VPUHUJcBa0oW3Ys4IZbiWaJeklUSqBlWRaJRBORGU8+27F93keyFCmZydg+mvyMx5YAAAAAAAAAAAAAAAAAAAAAAMDoriXdSdpIeumsbHbHfkV9T3Mt6bGBikXLY2KjuNV31l0DlclVbqnvvouEBtlIepfwuh5sJL1PeI1NfVMC8JLnWJoxV2er+r6pdRRoEwEwRwDMXWbYR8r3iJpKf4YPVV/OAOYIgDkCYI4AmCMA5giAOQJgjgCYIwDmCIA5AmCOAJgjAOYIgDkCYG7EAPyaeG5dYfuujBiAHxPPPVTYfjhzY89bc6X/39iReqNEdPvaivdPbwGQth11q+0pe717/KHi9jURgA58krSS9CzpSdK9pJtM+yYAjbvR9ixy2G5rSV8y7J8ANO5ex9tuo3gICEDjnjTdftEQEIDGzQUgGgIC0Ljvmm/DSAgIQOOOfQnMFQIC0IHPkn4qLQS/JX1bsO8uA9DzHDw1ypIzQXcBGGUOntJlrbQ/i7oLwEhz8JQuq4T2DPVPjililt4uPdIcPKX9kfRW030Q6p8RLwdjgXMEYOp6O/Y9KNPneETu7wDHrrdT9sta0seE9szdP1Xe4PX19nM3dItl6J+Bjpb+EfR1wb4JQOP4K9jcSuU6Xwn7DSMAp7vQ9rd8qc5Xwr7DCMDpUgLAgJDBTX0EMCTMAINCoRv9Gxb+vHvMsPCCXo83iK4ZlH0NnswIwIFj4w2iawZxa1ipN8hsarxBdM2glO1rIwAHpoaZpdzeHd2+tlD/nGNASGnR47WqLwNCzBEAcwTAHAEwRwDMEQBzBMAcATBHAMwRAHMEwBwBMEcAzBEAcwTA3GWGfbQ4KKSkoerLGcAcATBHAMylBGBqDZ3eRNcM6s1sfVMCMNKcPtE1g3qTZY2jUeb0ia4Z1FvJeiNLz3P6RNcMOvfx16gvAAAAAAAAAAAAAAAAAAAAAADo0F/5tv2gZkObIAAAAABJRU5ErkJggg=="
                                                   alt=""></span>
                                    </div>
                                    <div v-else>
                                        {{ data.spf > 9 ? data.spf : "0" + data.spf }} - {{ data.name_ru }}
                                    </div>
                                </td>
                            </template>
                            <template>
                               <td>
                                   <div class="text-right">
                                       {{ $n(data.income_data) }}
                                   </div>
                               </td>
                            </template>
                            <template>
                               <td>
                                   <template>
                                       <div title="Внимание! Расхождение по суммам!" v-if="(parseFloat(data.deviation) !== parseFloat(data.income_data)) && !empty_total" class="text-right"
                                            :class="{ 'error-deviation': (parseFloat(data.deviation) !== parseFloat(data.income_data)) && !empty_total }">
                                           {{ $n(data.deviation) }}
                                       </div>
                                       <div v-else class="text-right">
                                           {{ $n(data.deviation) }}
                                       </div>
                                   </template>
                               </td>
                            </template>

                            <td v-for="mon in calcFlds.slice(2)" :key="mon">
                                <template v-if="(data.type === 5)">
                                    <div>
                                        <b-form-input
                                            v-if="haveEditAccess()"
                                            class="text-right"
                                            :value="$n(data[mon])"
                                            @change="v => data[mon] = parseFloat(v.replace(/[^0-9]/g, ''))"
                                            @keyup.enter.exact="keyup13"
                                            @keypress="keyPress($event, '^[0-9]+$')"
                                            @blur="inputFixed(data, mon, data[mon])">
                                        </b-form-input>
                                        <div v-else class="text-right">
                                            {{ $n(data[mon]) }}
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="text-right">
                                        {{ $n(data[mon]) }}
                                    </div>
                                </template>
                            </td>
                            <td>
                                <template v-if="data.type === 1 && data.newItem">
                                    <b-dropdown
                                        id="dropdown-dropleft"
                                        dropleft class="more"
                                    >
                                        <template v-slot:button-content>
                                            <i class="icon icon-more"></i>
                                        </template>
                                        <template>
                                            <b-dropdown-item
                                                @click="deleteSpec(data)">
                                                Удалить категорию
                                            </b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                </template>
                                <template v-if="data.type === 2 && data.newItem">
                                    <b-dropdown
                                        id="dropdown-dropleft"
                                        dropleft class="more"
                                    >
                                        <template v-slot:button-content>
                                            <i class="icon icon-more"></i>
                                        </template>
                                        <template>
                                            <b-dropdown-item
                                                @click="deleteSpec(data)">
                                                Удалить класс
                                            </b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                </template>
                                <template v-if="data.type === 3 && data.newItem">
                                    <b-dropdown
                                        id="dropdown-dropleft"
                                        dropleft class="more"
                                    >
                                        <template v-slot:button-content>
                                            <i class="icon icon-more"></i>
                                        </template>
                                        <template>
                                            <b-dropdown-item
                                                @click="deleteSpec(data)">
                                                Удалить подкласс
                                            </b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                </template>
                                <template v-if="data.type === 5 && haveDeleteAccess()">
                                    <b-dropdown
                                        id="dropdown-dropleft"
                                        dropleft class="more"
                                    >
                                        <template v-slot:button-content>
                                            <i class="icon icon-more"></i>
                                        </template>
                                        <template>
                                            <b-dropdown-item
                                                @click="deleteSpec(data)">
                                                Удалить специфику
                                            </b-dropdown-item>
                                        </template>
                                    </b-dropdown>
                                </template>

                            </td>

                        </tr>
                        <tr>
                                <td></td>
                                <td class="text-left font-weight-bold" colspan="4">Итого (тыс. тенге)</td>
                                <td class="text-right font-weight-bold">{{ $n(total('income_data')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('deviation')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('january')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('february')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('march')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('april')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('may')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('june')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('july')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('august')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('september')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('october')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('november')) }}</td>
                                <td class="text-right font-weight-bold">{{ $n(total('december')) }}</td>
                                <td></td>
                            </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <c-income-add-item-modal
            v-if="reg && reg.hasOwnProperty('code') && reg.code"
            ref="addItemRef"
            :show="showAddItemModal"
            :data-table="budgetForm"
            :filter-kat="repKat"
            :filter-clss="repCls"
            :filter-spf="repSpec"
            :filter-pcl="repSubCls"
            :filter-year="repYear"
            :filter-region="reg.code"
            :is-empty-total="empty_total"
            @addItemClk="addNewItems"
            @shown="showAddItemModal = false"
        ></c-income-add-item-modal>
    </div>
</template>

<script>
import store from '@/services/store';
import { Ax } from '@/utils';
import axios from 'axios'
import CIncomeAddItemModal from '@/modules/budget/execution/form/tabs/components/IncomeAddItemModal.vue';


export default {
    name: 'BudgetExecutionIncome',
    components: {
        'c-income-add-item-modal': CIncomeAddItemModal
    },

    data() {
        return {
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'kat',
                    label: 'Категория'
                },
                {
                    key: 'clss',
                    label: 'Класс'
                },
                {
                    key: 'pcl',
                    label: 'Подкласс'
                },
                {
                    key: 'spf',
                    label: 'Специфика'
                },
                {
                    key: 'income_data',
                    label: 'доходы'
                },
                {
                    key: 'deviation',
                    label: 'Финансовый план на год',
                    variant: 'danger'
                },
                {
                    key: 'january',
                    label: 'Январь'
                },
                {
                    key: 'february',
                    label: 'Февраль'
                },
                {
                    key: 'march',
                    label: 'Март'
                },
                {
                    key: 'april',
                    label: 'Апрель'
                },
                {
                    key: 'may',
                    label: 'Май'
                },
                {
                    key: 'june',
                    label: 'Июнь'
                },
                {
                    key: 'july',
                    label: 'Июль'
                },
                {
                    key: 'august',
                    label: 'Август'
                },
                {
                    key: 'september',
                    label: 'Сентябрь'
                },
                {
                    key: 'october',
                    label: 'Октябрь'
                },
                {
                    key: 'november',
                    label: 'Ноябрь'
                },
                {
                    key: 'december',
                    label: 'Декабрь'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            calcFlds: [
                'income_data',
                'deviation',
                'january',
                'february',
                'march',
                'april',
                'may',
                'june',
                'july',
                'august',
                'september',
                'october',
                'november',
                'december'
            ],

            filter: {
                kat: null,
                clss: null,
                pcl: null,
                spf: null,
                name_ru: null
            },

            repYear: 0,
            repFilterData: {},
            repKat: {
                name: 'Все',
                code: null
            },
            repKatOpt: [],

            repCls: {
                name: 'Все',
                code: null
            },
            repClsOpt: [],

            repSubCls: {
                name: 'Все',
                code: null
            },
            repSubClsOpt: [],

            repSpec: {
                name: 'Все',
                code: null
            },
            repSpecOpt: [],
            obl: 0,
            listReg: null,
            reg: null,
            filtered: false,

            months: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],

            bar: 0,
            dictTree: null,
            budgetForm: [],
            open: true,
            isLoad: false,
            empty_total: false,
            moduleCode: '005.001.003',
            editAccess: null,
            signAccess: false,
            saveAccess: false,
            showAddItemModal: false,
            uniqueId: 0
        };
    },

    async created() {
        const date = new Date();
        this.repYear = date.getFullYear();
        if (!this.obl) await this.getObl();
        if (!this.editAccess) this.getEditAccess();
    },

    async mounted() {
        if (!this.obl) await this.getObl();
        if (!this.editAccess) this.getEditAccess();
        await this.loadDictTree();
        await this.loadRepFilterData()
        this.bar = 100;
    },

    beforeUpdate() {
        this.budgetForm.forEach(row => {
            if ([0, 1, 2, 3].includes(row.type)) {
                row._rowVariant = 'info';
            }
        });
    },

    methods: {
        haveEditAccess() {
            return this.editAccess && (this.editAccess.access_level === 2 || this.editAccess.access_level === 3)
        },
        haveDeleteAccess() {
            return this.editAccess && (this.editAccess.access_level === 2 || this.editAccess.access_level === 3)
        },
        getEditAccess() {
            const avModules = store.state.user.userModules
            for (const avModule of avModules) {
                if (avModule.modules === this.moduleCode) {
                    this.editAccess = Object.assign({}, avModule)
                    break
                }
            }
        },

        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        this.obl = json.obl;
                    });
                this.bar = 20;
                await this.loadRegions();
                this.reg = this.listReg[0]
            } catch (error) {
                this.bar = 100;
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + store.state.user.sub);
                this.listReg = await response.json();
                for (let i = 0; i < this.listReg.length; i++) {
                    this.listReg[i].name = this.listReg[i].code + ' - ' + this.listReg[i].name_ru
                }
                this.bar = 40;
            } catch (error) {
                this.bar = 100;
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов
        deleteSpfRemote(item) {
            const path = '/api/consolidated-income-plan'
            const deleteItems = [];
            const obj = {
                code: "",
                region: this.reg.code,
                year: this.repYear,
                id: item.id,
                kat: item.kat,
                clss: item.clss,
                pcl: item.pcl,
                spf: item.spf
            }
            deleteItems.push(obj)
            axios.delete(path, {data: deleteItems})
            .then(response => {
                console.log('Delete request successful');
            })
            .catch(error => {
                console.error('Error making delete request:', error.message);
            });
        },
        deleteSpec(data) {
            const el = Object.assign({}, data)
            for (let i = 0; i < this.budgetForm.length; i++) {
                if (this.budgetForm[i].id === data.id) {
                    if (this.budgetForm[i].type === 5 && !this.budgetForm[i].newItem) {
                        this.$bvModal.msgBoxConfirm(
                            'Вы действительно хотите удалить данную запись?',
                            {
                                title: 'Подтверждение',
                                size: 'lg',
                                buttonSize: 'sm',
                                okVariant: 'success',
                                okTitle: 'ДА',
                                cancelTitle: 'Отмена',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true
                            })
                            .then(value => {
                                if (value) {
                                    this.budgetForm.splice(i, 1);
                                    i--;
                                    this.deleteSpfRemote(el)
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    } else {
                        this.budgetForm.splice(i, 1);
                        i--;
                    }
                    // this.budgetForm.splice(i, 1);
                    // i--;
                } else if (this.budgetForm[i].parent_id === data.id) {
                    for (let j = 0; j < this.budgetForm.length; j++) {
                        if (this.budgetForm[j].parent_id === this.budgetForm[i].id) {
                            if (data.type === 1) {
                                for (let k = j; k < this.budgetForm.length; k++) {
                                    if (this.budgetForm[k].parent_id === this.budgetForm[j].id) {
                                        this.budgetForm.splice(k, 1);
                                        k--;
                                    }
                                }
                            }
                            this.budgetForm.splice(j, 1);
                            j--;
                        }
                    }
                    this.budgetForm.splice(i, 1);
                    i--;
                }
            }
        },
        createTable(elem, parent_id, idx = null) {
            const that = this;
            // создание таблицы на основе дерева
            const item = Object.assign({}, elem);

            this.uniqueId++;
            this.$set(item, 'id', this.uniqueId);
            this.$set(item, 'parent_id', parent_id);
            this.$set(item, 'visible', true);
            Object.defineProperty(item, 'parent', {
                get: function () {
                    for (const row of that.budgetForm) {
                        if (item.parent_id === row.id) {
                            return row;
                        }
                    }
                    return null;
                }
            });

            if (item.type === 5) {
                this.calcFlds.forEach((field) => {
                    if (item.hasOwnProperty(field)) {
                        if (!item[field]) {
                            this.$set(item, field, 0);
                        } else {
                            this.$set(item, field, parseFloat(item[field]));
                        }
                    } else {
                        if (field === 'deviation') {
                            Object.defineProperty(item, 'deviation', {
                                get: function () {
                                    const sum = (parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june)
                                        + parseFloat(item.july) + parseFloat(item.august) + parseFloat(item.september)
                                        + parseFloat(item.october) + parseFloat(item.november) + parseFloat(item.december));
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });


                            Object.defineProperty(item, 'checkFeb', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkMar', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkApr', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkMay', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april) + parseFloat(item.may);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkJun', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkJul', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june) + parseFloat(item.july);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkAug', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june) + parseFloat(item.july)
                                        + parseFloat(item.august);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkSep', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june) + parseFloat(item.july)
                                        + parseFloat(item.august) + parseFloat(item.september);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkOct', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june) + parseFloat(item.july)
                                        + parseFloat(item.august) + parseFloat(item.september) + parseFloat(item.october);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkNov', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june) + parseFloat(item.july)
                                        + parseFloat(item.august) + parseFloat(item.september) + parseFloat(item.october)
                                        + parseFloat(item.november);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                            Object.defineProperty(item, 'checkDec', {
                                get: function () {
                                    const sum = parseFloat(item.january) + parseFloat(item.february) + parseFloat(item.march)
                                        + parseFloat(item.april) + parseFloat(item.may) + parseFloat(item.june) + parseFloat(item.july)
                                        + parseFloat(item.august) + parseFloat(item.september) + parseFloat(item.october)
                                        + parseFloat(item.november) + parseFloat(item.december);
                                    return parseFloat((Math.round(sum * 10) / 10).toFixed(1));
                                }
                            });
                        } else {
                            this.$set(item, field, 0);
                        }
                    }
                });
            } else {
                this.$set(item, 'open', true);
                this.$set(item, 'hasChild', true);

                this.calcFlds.forEach(field => {
                    Object.defineProperty(item, field, {
                        get: function () {
                            return that.reSum(item, field);
                        }
                    });
                });
            }

            if (item) {
                this.$set(item, 'index', this.budgetForm.length);
                if (idx === null) {
                    this.$set(this.budgetForm, this.budgetForm.length, item);
                } else {
                    this.budgetForm.splice(idx, 0, item)
                }
            }
            if (idx !== null) return
            if (item.hasChild) {
                switch (item.type) {
                    case 1:
                        item.child_list.sort((a, b) => a.clss - b.clss);
                        break
                    case 2:
                        item.child_list.sort((a, b) => a.pcl - b.pcl);
                        break
                    case 3:
                        item.child_list.sort((a, b) => a.spf - b.spf);
                        break

                }
                for (const ch of item.child_list) {
                    this.createTable(ch, item.id);
                }
                delete item.child_list;
            }
        }, // древовидную выборку преобразовывает в таблицу (для отображения)

        addNewKatClssPclSpf(item, parentIndex = 0, type, desc, create = false) {
            let isExist = false;
            let rowIndex = 0
            const parent = this.budgetForm[parentIndex]
            for (let i = parentIndex; i < this.budgetForm.length; i++) {
                const el = this.budgetForm[i]
                if (el.type === type && el[desc] === item[desc] && (type === 1 ? true: el.parent_id === parent.id)) {
                    isExist = true
                    rowIndex = i
                    break
                }
            }
            if (!isExist) {
                let dataItem = {}
                this.uniqueId++;
                dataItem = {
                    id: this.uniqueId,
                    year: 2023,
                    region: this.reg.code,
                    kat: item.kat,
                    clss: type > 1 ? item.clss : null,
                    pcl: type > 2 ? item.pcl : null,
                    spf: type > 3 ? item.spf : null,
                    name_ru: type === 1 ? item.katName: (type === 2 ? item.clssName: (type === 3 ? item.pclName: item.name_ru)),
                    type: type,
                    income_data: 0.0,
                    month: [],
                    hasChanges: true,
                    newItem: true
                }
                this.calcFlds.slice(2).forEach((calcF, index) => {
                    dataItem[calcF] = 0
                    dataItem.month.push({
                        month: index,
                        value: 0
                    })
                })
                this.uniqueId++;
                let lowIndex = this.budgetForm.length
                for (let i = type === 1 ? 0: parentIndex+1; i < this.budgetForm.length; i++) {
                    const el = this.budgetForm[i]
                    if (el.type <= parent.type && type !== 1) {
                        lowIndex = i;
                        break;
                    }
                    if (el.type === type && item[desc] < el[desc] && (type === 1 ? true: el.parent_id === parent.id)) {
                        lowIndex = i;
                        break;
                    }
                }
                this.createTable(dataItem, type === 1 ? 0: parent.id, lowIndex)
                rowIndex = lowIndex
            }

            if (parent !== undefined && !parent.open && type !== 1) this.openChilds(parent);
            return rowIndex;
        },

        addNewItem(item, create = false) {
            this.uniqueId++;
            const newItem = {
                id: this.uniqueId,
                ...item
            }

            const katIndex = this.addNewKatClssPclSpf(newItem, 0, 1, 'kat', create)
            const clssIndex = this.addNewKatClssPclSpf(newItem, katIndex, 2, 'clss', create)
            const pclIndex = this.addNewKatClssPclSpf(newItem, clssIndex, 3, 'pcl', create)
            this.addNewKatClssPclSpf(newItem, pclIndex, 5, 'spf', create)
            for (let i = 0; i < this.budgetForm.length; i++) {
                this.budgetForm[i].index = i
            }

        },

        addNewItems(items) {
            for (const item of items) {
                this.addNewItem(item)
            }
        },

        sortedTable(item) {
            if ([1, 2, 3].includes(item.type)) {
                for (const ch of item.child_list) {
                    this.sortedTable(ch)
                }
            } else {
                this.addNewItem(item, true)
            }
        },

        openModal(item) {
            this.$bvModal.msgBoxConfirm(
                'Вы хотите выполнить разноску годовой суммы бюджета по месяцам?',
                {
                    title: 'Подтверждение',
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    okTitle: 'ДА',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        const monthlyShare = Math.floor(item.income_data / 12);
                        let newSum = 0;
                        for (const mon of this.months) {
                            item[mon] = parseInt(monthlyShare.toString())
                            newSum += item[mon]
                        }
                        const dif = item.income_data - newSum
                        if (dif !== 0) {
                            item.december += dif;
                        }
                        item.hasChanges = true
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }, // открывает модалку в котором можно распределить значению план на год на 12 месяцев

        inputFixed(item, field, value) {
            item.hasChanges = true
            if (value) {
                this.$set(item, field, parseFloat(value));
            } else {
                this.$set(item, field, 0);
            }
        },

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            if (!event.target.value) {
                event.target.value = 0;
            }
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');

            const regex = new RegExp(pattern);
            const charCode = event.keyCode;
            const key = String.fromCharCode(charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
            return true;
        }, // вводит по заданному паттерну

        async loadDictTree() {
            try {
                await this.budgetForm.splice(0);
                this.dictTree = null;
                this.bar = 60;
                let path = '/api/consolidated-income-plan?year=' + this.repYear + '&region=' + this.reg.code
                if (this.repKat && this.repKat.hasOwnProperty('code') && this.repKat.code) {
                    path += '&kat=' + this.repKat.code
                    if (this.repCls && this.repCls.hasOwnProperty('code') && this.repCls.code) {
                        path += '&cls=' + this.repCls.code
                        if (this.repSubCls && this.repSubCls.hasOwnProperty('code') && this.repSubCls.code) {
                            path += '&pcl=' + this.repSubCls.code
                            if (this.repSpec && this.repSpec.hasOwnProperty('code') && this.repSpec.code) {
                                path += '&spf=' + this.repSpec.code
                            }
                        }
                    }
                }
                // const response = await fetch('/api-py/budget-execution-income/dict-ebk-doh-tree/' + this.repYear + '/' + this.reg);
                const response = await fetch(path);
                this.dictTree = await response.json();
                this.bar = 80;
                this.prepareForm();
            } catch (error) {
                this.bar = 100;
                this.makeToast('danger', 'Ошибка запроса loadDictTree', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        openAll(forceOpen) {
            if (forceOpen) {
                if (forceOpen === 'open') {
                    this.open = true;
                } else {
                    this.open = false;
                }
            } else {
                this.open = !this.open;
            }
            for (const row of this.budgetForm) {
                row.visible = this.open;
                if ([1, 2, 3, 4].includes(row.type)) {
                    row.open = this.open;
                }
                if ([0, 1].includes(row.type)) {
                    row.visible = true;
                }
            }
        }, // открывает.закрывает все ветки

        openChilds(parent, bool) {
            parent.open = (bool === undefined ? !parent.open : bool);

            for (const row of this.budgetForm) {
                if (parent.id === row.parent_id) {

                    row.visible = parent.open;
                    if (!parent.open) {
                        if ([1, 2, 3].includes(row.type)) {
                            this.openChilds(row, parent.open);
                        }
                    }

                }
            }
        }, // открывает/закрывает ветку до конечного элемента

        prepareForm() {
            this.dictTree.sort((a, b) => a.kat - b.kat)
            for (const item of this.dictTree) {
                this.createTable(item, 0);
            }
            this.empty_total = (this.budgetForm.length === 0 || this.total('income_data') === 0);
        }, // подготовка отображения данных

        reSum(parent, field) {
            let sum = 0;

            this.budgetForm.forEach(row => {
                if ((row.parent_id === parent.id)
                    && (row.type !== parent.type)) {
                    if (!row[field]) {
                        sum += 0;
                    } else {
                        sum += parseFloat(row[field]);
                    }
                    // sum += parseFloat(row[field]);
                }
            });
            return parseFloat(sum.toFixed(2));
        }, // пересчет суммы

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        toNum(value, fix = 1) {
            if (typeof (value) === 'string') {
                return parseFloat(parseFloat(value).toFixed(fix));
            }
            if (typeof (value) === 'number') {
                return parseFloat(value.toFixed(fix));
            }
            if (typeof (value) === 'boolean') {
                return (value === true ? 1 : 0);
            }
            if (typeof (value) === 'undefined' || isNaN(value) || isFinite(value) || (value === null)) {
                return 0;
            }
        },

        total(field) {
            let sum = 0;
            for (const row of this.budgetForm) {
                if (row.type === 1) {
                    sum += parseFloat(row[field]);
                }
            }
            return parseFloat(sum.toFixed(2));
        }, // итого по заданному полю

        //= ========== фильтрация данных в столбцах ===============

        filterFunction(row, val) {
            const { kat, clss, pcl, spf, name_ru } = val;
            const isOpen = [
                !kat || Number(kat) === row.kat,
                !clss || Number(clss) === row.clss,
                !pcl || Number(pcl) === row.pcl,
                !spf || Number(spf) === row.spf,
                !name_ru || row.name_ru.toLowerCase().indexOf(name_ru.toLowerCase()) !== -1
            ].every(Boolean);
            if ((kat || clss || pcl || spf || name_ru) && !this.filtered) {
                this.filtered = true;
                this.openAll('open');
            }
            if (!(kat || clss || pcl || spf || name_ru) && this.filtered) {
                this.filtered = false;
                this.openAll('close');
            }
            return isOpen;
        },

        async prepareForSave() {
            this.isLoad = true;
            const dataToSave = {
                user_name: '',
                user_id: this.$store.state.user.sub,
                region: this.reg.code,
                year: this.repYear,
                execution_income_list: []
            };

            let errorRow = false;
            let katParent = null;
            let clssParent = null;
            let pclParent = null;
            this.budgetForm.forEach(el => {
                if (el.type === 1) katParent = el
                if (el.type === 2) clssParent = el
                if (el.type === 3) pclParent = el

                if (el.type === 5 && (el.hasChanges || el.newItem)) {
                    // save anyway but allert if not equal
                    const isValid = (parseFloat(katParent.deviation) !== parseFloat(katParent.income_data) && parseFloat(clssParent.deviation) !== parseFloat(clssParent.income_data) && parseFloat(pclParent.deviation) !== parseFloat(pclParent.income_data)) || this.empty_total
                    // const isValid = (parseFloat(katParent.deviation) <= parseFloat(katParent.income_data) && parseFloat(clssParent.deviation) <= parseFloat(clssParent.income_data) && parseFloat(pclParent.deviation) <= parseFloat(pclParent.income_data)) || this.empty_total
                    let newAndEmpty = true;
                    this.months.forEach(m => {
                        if (el[m] !== 0) newAndEmpty = false;
                    })

                    const elItem = {};
                    elItem.code = '';
                    elItem.region = this.reg.code;
                    elItem.year = this.repYear;
                    elItem.id = el.id;
                    elItem.kat = el.kat;
                    elItem.clss = el.clss;
                    elItem.pcl = el.pcl;
                    elItem.spf = el.spf;
                    this.months.forEach(month => elItem[month] = el[month]);

                    dataToSave.execution_income_list.push(elItem);
                    if ((isValid || (el.newItem && newAndEmpty))) {
                        errorRow = true;
                    }
                }
            });

            if (dataToSave.execution_income_list && dataToSave.execution_income_list.length > 0) {
                await this.save(dataToSave.execution_income_list, errorRow);
            } else {
                this.makeToast('success', 'Сообщение', 'Нету измененных данных');
                this.isLoad = false;
            }
        }, // подготовка данных к сохранению

        async save(values, error = false) {
            try {
                const response = await fetch('/api/consolidated-income-plan', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                // const result = await response.json();
                if (response.status === 200) {
                    if (error && !this.empty_total) {
                        this.makeToast('warning', 'Сообщение', 'Сводный план поступлений сохранен, но имеет расхождения!');
                        await this.chgData();
                    } else {
                        this.makeToast('success', 'Сообщение', 'Сводный план поступлений сохранен успешно');
                        await this.chgData();
                    }
                } else {
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async signAndSend() {
            console.log('Подписать')
        }, // подписать и отправить

        async chgData() {
            this.$refs.drop.hide(true);
            this.uniqueId = 0;
            await this.loadDictTree();
            this.$refs.addItemRef.loadKat()
            this.bar = 100;
        },
        async chgRepKat() {
            this.repClsOpt = []
            this.repSubClsOpt = []
            this.repSpecOpt = []
            this.repCls = null
            this.repSubCls = null
            this.repSpec = null
            if (!(this.repKat && this.repKat.hasOwnProperty('code') && this.repKat.code)) return
            await this.loadClsOpt()

        },
        async chgRepCls() {
            this.repSubClsOpt = []
            this.repSpecOpt = []
            this.repSubCls = null
            this.repSpec = null
            if (!(this.repCls && this.repCls.hasOwnProperty('code') && this.repCls.code)) return
            await this.loadPclOpt()
        },
        async chgRepSubCls() {
            this.repSpec = null
            this.repSpecOpt = []
            if (!(this.repSubCls && this.repSubCls.hasOwnProperty('code') && this.repSubCls.code)) return
            await this.loadSpfOpt()
        },
        chgRepSpec() {
            console.log('last filter item')
        },
        async loadKatOpt() {
            const path = `/api-py/budget-execution-income/filter/kat/${this.repYear}/${this.reg.code}`
            let data = []
            await axios.get(path).then(resp => {
                data = resp.data
            })
            this.repKatOpt = [
                {
                    name_ru: 'Все',
                    code: null
                }
            ]
            for (const re of data) {
                this.repKatOpt.push(
                    {
                        kat: re.kat,
                        code: re.kat,
                        name_ru: `${re.kat} - ${re.name_ru}`
                    }
                )
            }
            this.repKat = {
                name_ru: 'Все',
                code: null
            }
            if (this.repKatOpt.length === 1) {
                this.repKatOpt = []
                this.repKat = null
            }
        },
        async loadClsOpt() {
            const path = `/api-py/budget-execution-income/filter/cls/${this.repYear}/${this.reg.code}/${this.repKat.code}`
            let data = []
            await axios.get(path).then(resp => {
                data = resp.data
            })
            this.repClsOpt = [
                {
                    name_ru: 'Все',
                    code: null
                }
            ]
            for (const re of data) {
                this.repClsOpt.push(
                    {
                        cls: re.cls,
                        code: re.cls,
                        name_ru: `${re.cls} - ${re.name_ru}`
                    }
                )
            }
            this.repCls = {
                name_ru: 'Все',
                code: null
            }
            if (this.repClsOpt.length === 1) {
                this.repClsOpt = []
                this.repCls = null
            }
        },
        async loadPclOpt() {
            const path = `/api-py/budget-execution-income/filter/pcl/${this.repYear}/${this.reg.code}/${this.repKat.code}/${this.repCls.code}`
            let data = []
            await axios.get(path).then(resp => {
                data = resp.data
            })
            this.repSubClsOpt = [
                {
                    name_ru: 'Все',
                    code: null
                }
            ]

            for (const re of data) {
                this.repSubClsOpt.push(
                    {
                        pcl: re.pcl,
                        code: re.pcl,
                        name_ru: `${re.pcl} - ${re.name_ru}`
                    }
                )
            }
            this.repSubCls = {
                name_ru: 'Все',
                code: null
            }
            if (this.repSubClsOpt.length === 1) {
                this.repSubClsOpt = []
                this.repSubCls = null
            }
        },
        async loadSpfOpt() {
            const path = `/api-py/budget-execution-income/filter/spf/${this.repYear}/${this.reg.code}/${this.repKat.code}/${this.repCls.code}/${this.repSubCls.code}`
            let data = []
            await axios.get(path).then(resp => {
                data = resp.data
            })
            this.repSpecOpt = [
                {
                    name_ru: 'Все',
                    code: null
                }
            ]
            for (const re of data) {
                this.repSpecOpt.push(
                    {
                        spf: re.spf,
                        code: re.spf,
                        name_ru: `${re.spf} - ${re.name_ru}`
                    }
                )
            }
            this.repSpec = {
                name_ru: 'Все',
                code: null
            }
            if (this.repSpecOpt.length === 1) {
                this.repSpecOpt = []
                this.repSpec = null
            }
        },
        async loadRepFilterData() {
            this.repKatOpt = []
            this.repKat = null
            this.repClsOpt = []
            this.repCls = null
            this.repSubClsOpt = []
            this.repSubCls = null
            this.repSpecOpt = []
            this.repSpec = null
            this.loadKatOpt()
        },
        kat() {
            const res = []
            for (const re of this.repKatOpt) {
                res.push({
                    kat: re.kat,
                    code: re.kat,
                    name_ru: `${re.kat} - ${re.name_ru}`
                })
            }
            return res
        },
        cls() {
            const res = []
            for (const re of this.repClsOpt) {
                res.push({
                    cls: re.cls,
                    code: re.cls,
                    name_ru: `${re.cls} - ${re.name_ru}`
                })
            }
            return res
        },
        pcl() {
            const res = []
            for (const re of this.repSubCls) {
                res.push({
                    pcl: re.pcl,
                    code: re.pcl,
                    name_ru: `${re.pcl} - ${re.name_ru}`
                })
            }
            return res
        },
        spf() {
            const res = []
            for (const re of this.repSpecOpt) {
                res.push({
                    spf: re.spf,
                    code: re.spf,
                    name_ru: `${re.spf} - ${re.name_ru}`
                })
            }
            return res
        },

        openFilterByRef(refName) {
            const drop = this.$refs.drop;
            drop.show(true);
            const refItem = this.$refs[refName];
            setTimeout(() => refItem.$el.focus(), 100);
        },

        downloadRepConsolidatedIncomePlan14() {
            const param = {
                year: this.repYear,
                region_id: this.reg.code
            };
            Ax(
                {
                    url: '/api-py/download-consolidated-income-plan/14/' + JSON.stringify(param),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Сводный план поступлений в бюджет.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRepConsolidatedIncomePlan14()', error.toString());
                }
            );
        },

        downloadRepConsolidatedIncomePlan15() {
            const param = {
                year: this.repYear,
                region_id: this.reg.code
            };
            Ax(
                {
                    url: '/api-py/download-consolidated-income-plan/15/' + JSON.stringify(param),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Сводный план поступлений в бюджет.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRepConsolidatedIncomePlan15()', error.toString());
                }
            );
        }
    }
};
</script>

<style scoped>
.filtered {
    color: #95B8CD;
    display: inline-block;
    margin-right: 5px;
}
.pr-level {
    background-color: #ecf7ff
}
.ppr-level {
    background-color: rgba(236, 247, 255, 0.6)
}
.data-expand {
    position: absolute;
    top: 0;
    right: 0;
}

.error-deviation {
    color: #C0504C;
}
</style>